import axios from "axios";
//import router from 'vue-router';

// Account
export const login = ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
        commit("loginRequest");
        axios
            .post("/api/token", payload)
            .then(response => {
                const auth = response.data;

                // need double auth?
                if (!auth.needDoubleAuthentication) {
                    axios.defaults.headers.common["Authorization"] = `Bearer ${auth.access_token}`;

                    commit("loginSuccess", auth);
                    // init signalr
                    commit("initConnection", auth.access_token)
                    commit("setCloseToExpiration", auth.closeToExpiration)
                } else {
                    //commit("loginSuccess", auth);
                    commit("loginError");
                }
                
                resolve(response);
            })
            .catch(error => {
                commit("loginError");
                delete axios.defaults.headers.common["Authorization"];
                reject(error.response);
            });
    });
};

export const loginDoubleAuth = ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
        commit("loginRequest");
        axios
            .post("/api/token/check-code", payload)
            .then(response => {
                const auth = response.data;

                // need double auth?
                axios.defaults.headers.common["Authorization"] = `Bearer ${auth.access_token}`;

                commit("loginSuccess", auth);
                // init signalr
                commit("initConnection", auth.access_token)

                commit("setCloseToExpiration", auth.closeToExpiration)

                resolve(response);
            })
            .catch(error => {
                commit("loginError");
                delete axios.defaults.headers.common["Authorization"];
                reject(error.response);
            });
    });
};

export const register = ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
        commit("registerRequest");
        axios
            .post("/api/account", payload)
            .then(response => {
                commit("registerSuccess");
                resolve(response);
            })
            .catch(error => {
                commit("registerError");
                reject(error.response);
            });
    });
};

export const subscribe = ({ state, commit }) => {
    return new Promise((resolve, reject) => {
        //commit("changeRole", "Subscriber");
        const payload = {
            refresh_token: state.auth.refresh_token
        };

        return axios
            .post("/api/token/refresh", payload)
            .then(response => {
                const auth = response.data;
                axios.defaults.headers.common["Authorization"] = `Bearer ${auth.access_token}`;
                commit("loginSuccess", auth);
                commit("initConnection", auth.access_token)

                return resolve(true)
            })
            .catch(error => {
                return reject(error);
            });
    });
}

export const logout = ({ state, commit }) => {
    commit("unsetBackoffice");
    commit("logout");
    delete axios.defaults.headers.common["Authorization"];
    state.connection.stop()
    //window.location.href = '/'
    /*router.push({ path: "/" })*/
};

// Affichage header backoffice
export const switchBackoffice = ({ state, commit }) => {
    if (state.isBackoffice) {
        commit("unsetBackoffice")
    } else {
        commit("setBackoffice")
    }
};

// connexion singnalr
export const setConnection = ({ state, commit }) => {
    let loginToken = state.auth.access_token
    commit("initConnection", loginToken)
    
}

// Gestion de l'affichage des visites, likes et messages dans le header
export const getInteractionCounts = ({ commit }) => {
    axios.get('/api/profiles/interactions')
        .then(response => {
            commit("setVisits", response.data.visits)
            commit("setShowVisits", response.data.showVisits)
            commit("setLikes", response.data.likes)
            commit("setShowLikes", response.data.showLikes)
            commit("setMessages", response.data.messages)
            commit("setShowMessages", response.data.showMessages)
            commit("setUnseenEvents", response.data.unseenEvents)
        })
}

export const changeUnseenEvents = ({ commit }, payload) => {
    commit("setUnseenEvents", payload)
}
export const incrementEvents = ({ state, commit }) => {
    let count = state.unseenEventsCount
    count = count + 1
    commit("setUnseenEvents", count)

}
export const incrementVisits = ({state, commit }, payload) => {
    let count = state.visitCount
    count = count + payload
    commit("setVisits", count)
    if (count > 0) {
        commit("setShowVisits", true)
    }
}
export const resetVisits = ({ commit }) => {
    commit("setVisits", 0)
}
export const incrementLikes = ({ state, commit }, payload) => {
    let count = state.likeCount
    count = count + payload
    commit("setLikes", count)
    if (count > 0) {
        commit("setShowLikes", true)
    }
}
export const resetLikes = ({ commit }) => {
    commit("setLikes", 0)
}
export const incrementMessages = ({ state, commit }, payload) => {
    let count = state.messageCount
    count = count + payload
    if (count < 0) {
        count = 0
    }
    commit("setMessages", count)
    if (count > 0) {
        commit("setShowMessages", true)
    }
}
export const resetMessages = ({ commit }) => {
    commit("setMessages", 0)
}

export const showMessages = ({ commit }) =>{
    commit("setShowMessages", true)
}

export const isCloseToExpiration = ({ commit }, payload) => {
    commit("setCloseToExpiration", payload)
}

export const completeSubscriber = ({ commit }) => {
    commit("changeRole", "Subscriber")
}


//export const changeStatus = ({ commit }, payload) => {
//        commit('setStatus', payload)
//};
