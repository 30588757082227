<template>
    <main role="main">
        <div class="container">

            <div id="chatroom" class="pt-5 pb-5">
                <div class="row">
                    <!-- Colonne de gauche  -->
                    <div class="col-md-4" id="chatroom-left-column">
                        <!-- Chatroom profiles  -->
                        <div id="chatroom-profiles">
                            <div id="chatroom-profiles-header">
                                <h2>{{ $t("Toutes les conversations") }}</h2>
                            </div>
                            <div id="chatroom-profiles-list" v-if="data">

                                <!-- Chatters  -->
                                <chatter v-for="chatter in data.chatters" :key="chatter.userName" :chatter="chatter" :currentChatter="data.chatterUserName" @click.prevent="loadConversation(chatter.userName)"></chatter>

                                <div id="chatroom-profiles-footer"></div>
                            </div>
                        </div>
                        <!-- END Chatroom profiles   -->
                    </div>
                    <!-- END Colonne de gauche  -->

                    <div class="col-md-8" id="chatroom-right-column" @focusout="closeMenu">
                        <!-- Message de confirmation de blocage -->
                        <!--<div class="alert alert-warning" role="alert" v-if="isBlocked">
        Vous avez bloqué ce profil. Cet utilisateur n'aura plus accès à votre profil et vous n'aurez plus accès au sien.
        Cliquez <a href="#" @click.prevent="unblock">ici</a> si vous souhaitez annuler cette action.
    </div>-->
                        <!-- END Message de confirmation de blocage -->
                        <!-- Block & report buttons -->
                        <div id="profile-action-buttons">
                            <a href="#" @click.prevent="showBlock = true" v-if="!isBlocked" class="profile-action-button tooltips">
                                <span class="tooltiptexts">{{ $t('Bloquer') }}</span>
                                <img src="../../public/images/picto-user-block.svg" alt="{{ $t('Bloquer') }}" id="block_button" />
                            </a><br>
                            <a href="#" @click.prevent="showReport = true" class="profile-action-button tooltips" v-if="!isReported">
                                <span class="tooltiptexts">{{ $t('Signaler') }}</span>
                                <img src="../../public/images/picto-user-claim.svg" alt="{{ $t('Signaler') }}" id="report_button" />
                            </a>

                        </div>

                        <!-- modals -->
                        <block-modal :show="showBlock" @update="showBlock = $event" @block="block" />
                        <report-modal :show="showReport" @update="showReport = $event" @report="report($event)" />

                        <!-- Chatroom discussion  -->
                        <conversation :conversation="data.conversation" v-if="data" @new-message="addMessage($event)" @load-more="loadMore" ref="convers"></conversation>

                    </div>
                </div>
            </div>

        </div>
        <gallery v-if="open && pictures" :pictures="pictures" :initial="index" @close="open = false" />
    </main>
</template>

<script>
    import axios from "axios";
    import Chatter from "@/components/chat/Chatter"
    import Conversation from "@/components/chat/Conversation"
    import Gallery from "@/components/profile/Gallery.vue"
    import BlockModal from "@/components/modals/BlockModal"
    import ReportModal from "@/components/modals/ReportModal"

    export default {
        name: 'chat',
        components: {
            Chatter,
            Conversation,
            Gallery,
            BlockModal,
            ReportModal
        },
        data() {
            return {
                messages: [],
                data: null,
                alertMenu: false,
                isBlocked: false,
                isReported: false,
                showBlock: false,
                showReport: false,
                open: false,
                index: 0,
            }
        },
        mounted() {
            // receive message
            var ref = this
            ref.$store.dispatch("resetMessages", 1)
            this.$store.getters.getConnection.on("ReceiveMessage", function (sender, senderPseudo, msg) {
                if (sender == ref.data.chatterUserName) {
                    ref.pushMessage(msg, sender, senderPseudo)
                    setTimeout(function () { ref.$refs.convers.scrollToEnd() }, 300);
                } else {
                    //console.log('receive message')
                    let unread = ref.data.chatters.filter(c => c.userName == sender)[0].unreadMessageCount
                    ref.data.chatters.filter(c => c.userName == sender)[0].unreadMessageCount = unread + 1
                    ref.$store.dispatch("incrementMessages", 1)
                }
                ref.data.chatters.filter(c => c.userName == sender)[0].lastMessage.message = msg
            })
        },
        computed: {
            pictures() {
                let imageMessages = this.data.conversation.messages.filter(m => m.messageText.substr(0, 10) == 'data:image')
                let i = 0
                let images = []
                for (i; i < imageMessages.length; i++) {
                    images[i] = imageMessages[i].messageText
                }
                return images
            }
        },
        methods: {
            addMessage: function (msg) {
                let ref = this
                try {
                    this.$store.getters.getConnection.invoke("SendMessage", this.data.chatterUserName, this.data.pseudo, msg)
                } catch {
                        console.log('SEND ERROR: ');
                        // reconnect
                        ref.$store.dispatch("setConnection")
                        // try again
                        ref.$store.getters.getConnection.invoke("SendMessage", ref.data.chatterUserName, ref.data.pseudo, msg)
                }
                this.pushMessage(msg, this.data.userName, this.data.pseudo)
                setTimeout(function () { ref.$refs.convers.scrollToEnd() }, 300);
                this.data.chatters.filter(c => c.userName == this.data.chatterUserName)[0].lastMessage.message = msg
                ref.$store.dispatch("showMessages")
            },
            pushMessage(msg, username, pseudo) {
                //console.log("pseudo push = " + pseudo)
                let message = {}
                message.messageText = msg
                message.pseudo = pseudo
                message.userName = username
                message.messageDate = new Date()
                this.data.conversation.messages.push(message)
            },
            setData(data) {
                this.data = data;
            },
            loadConversation(chatter) {
                let unread = this.data.chatters.filter(c => c.userName == chatter)[0].unreadMessageCount
                this.$store.dispatch("incrementMessages", -unread)
                this.data.chatters.filter(c => c.userName == chatter)[0].unreadMessageCount = 0;

                this.data.chatterUserName = chatter
                axios.get(`/api/chat/conversation/${chatter}/0`)
                    .then(response => {
                        this.data.conversation = response.data
                        var ref = this
                        setTimeout(function () {
                            ref.$refs.convers.scrollToEnd()

                            const element = document.querySelector('#chatroom-discussion')
                            const topPos = element.getBoundingClientRect().top + window.pageYOffset

                            window.scrollTo({
                                top: topPos, // scroll so that the element is at the top of the view
                                behavior: 'smooth' // smooth scroll
                            })
                        }, 100);

                    });
            },
            loadMore() {
                if (this.data.conversation.isMore) {
                    axios.get(`/api/chat/conversation/${this.data.chatterUserName}/${this.data.conversation.firstMessageId}`)
                        .then(response => {
                            this.data.conversation.isMore = response.data.isMore
                            this.data.conversation.firstMessageId = response.data.firstMessageId
                            this.data.conversation.messages = response.data.messages.concat(this.data.conversation.messages)
                            //var ref = this
                            //setTimeout(function () { ref.$refs.convers.scrollToEnd() }, 100);
                            this.$refs.convers.loadMoreScroll()
                        });
                }
            },
            block() {
                axios.get(`/api/profiles/block/${this.data.chatterUserName}`)
                this.$router.push(`/${this.$i18n.locale}/homepage?block=${this.data.chatterPseudo}`)
                //this.isBlocked = true
                //this.showBlock = false
            },
            report(reason) {
                this.isReported = true
                axios.post('/api/profiles/report', { targetUserName: this.data.chatterUserName, reason: reason })
            },
            closeMenu() {
                let ref = this
                setTimeout(function () {
                    ref.alertMenu = false
                }, 200)
            },
            openGallery(image) {
                this.index = this.pictures.indexOf(image);
                this.open = true;
            },
        },
        beforeRouteEnter(to, from, next) {
            let url = '/api/chat/index'
            if (to.params.username != null && to.params.username != undefined && to.params.username != '') {
                url += '/' + to.params.username
            }
            axios.get(url)
                .then(response => {
                    next(vm => vm.setData(response.data));
                });
        },
    }
</script>

<style lang="scss">
    /* Tooltip container */
    .tooltips {
        position: relative;
        display: inline-block;
        border-bottom: none;
    }

        .tooltips .tooltiptexts {
            visibility: hidden;
            width: 120px;
            background-color: #555;
            color: #fff;
            text-align: center;
            border-radius: 6px;
            padding: 5px 0;
            position: absolute;
            z-index: 1;
            bottom: 125%;
            left: 50%;
            margin-left: -60px;
            opacity: 0;
            transition: opacity 0.3s;
            font-size: 14px;
        }

            .tooltips .tooltiptexts::after {
                content: "";
                position: absolute;
                top: 100%;
                left: 50%;
                margin-left: -5px;
                border-width: 5px;
                border-style: solid;
                border-color: #555 transparent transparent transparent;
            }

        .tooltips:hover .tooltiptexts {
            visibility: visible;
            opacity: 1;
        }
</style>
