<template>
    <main role="main">
        <div class="container">
            <div class="row">
                <div class="col-md-12 mt-3">
                    <router-link :to="{ name: 'admin-profile-create', params: 'fr' }" class="btn btn-secondary float-right">Créer un profil</router-link>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 text-center mb-4">
                    <h1>Profils</h1>
                </div>
            </div>
        </div>

        <!-- Moteur de recherche -->
        <div id="back-profile-search" class="bg-form-block">
            <div class="container">
                <div class="row">
                    <div class="d-inline-block w-100 mt-4 mb-3">
                        <div class="col-md-12">
                            <div id="back-search-filters-settings-form" class="d-flex">
                                <div class="settings-form-col">
                                    <div class="form-group">
                                        <label for="searchText" class="field-label">Rechercher par pseudo / nom / email</label>
                                        <input type="text" id="searchText" class="form-control" v-model="searchString" @input="setQuery">
                                    </div>
                                </div>

                                <div class="settings-form-col">
                                    <div class="form-group">
                                        <label for="searchStatus" class="field-label">Statut</label>
                                        <select class="form-control" id="searchStatus" @change="setStatus" v-model="searchStatus">
                                            <option v-for="status in statusList" :key="status" :value="status.value">{{ status.label }}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <!--<div id="search-filters-submit" class="mt-4 mb-4">
                            <a href="#" class="btn btn-primary">{{ $t("Voir les résultats") }}</a>
                        </div>-->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- END Moteur de recherche -->
        <!-- Message -->
        <div class="container">
            <div class="row">
                <div class="col-md-12 mb-2">
                    <div class="alert alert-success" role="alert" v-if="message">
                        {{ message }}
                    </div>
                </div>
            </div>
        </div>

        <!-- Résultats -->
        <div id="back-search-results" class="mt-5 mb-5">
            <div class="container">
                <div class="row">
                    <div class="col-md-12 mb-2">
                        <h2>Profils correspondants</h2>
                    </div>
                    <!-- RESULTS -->
                    <div v-if="profiles!=null && profiles.length>0" class="profiles-grid">
                        <div class="profiles-grid d-flex">
                            <profile-bloc v-for="profile in profiles" :profile="profile" :key="profile.slug"></profile-bloc>
                        </div>
                        <div class="col-md-12 text-center mt-4 mb-2" v-if="!noMore">
                            <button class="btn btn-secondary" @click="loadMore">{{ $t('Plus de profils') }}</button>
                        </div>
                    </div>

                    <!-- NO RESULTS -->
                    <div v-if="profiles==null || profiles.length==0">
                        <h4>Aucun profil ne correspond à vos critères de recherche</h4>
                    </div>
                </div>
            </div>
        </div>
        <!-- END Résultats -->
    </main>
</template>

<script>
    import ProfileBloc from "../../components/admin/profiles/ProfileBloc.vue"
    import axios from "axios";
    import _ from "lodash";

    export default {
        name: "admin-profiles",
        components: {
            ProfileBloc
        },
        data() {
            return {
                profiles: null,
                statusList: [],
                searchString: '',
                searchStatus: '',
                pages: 0,
                noMore: false,
                perPage: 15,
                message: ''
            };
        },
        mounted() {
            this.searchString = this.$route.query.q || "";
            this.searchStatus = this.$route.query.s || "Registered";
            this.pages = this.$route.query.p || 0;
            if (this.$route.query.m) {
                if (this.$route.query.m == 'del') {
                    this.message = 'Le profil a bien été supprimé'
                }
                let query = Object.assign({}, this.$route.query);
                delete query.m;
                this.$router.replace({ query });
            }
        },
        methods: {
            setData(profiles, statusList) {
                this.profiles = profiles;
                this.statusList = statusList;
                // 15 par page
                if (profiles.length < this.perPage) {
                    this.noMore = true
                }
            },
            setQuery: _.debounce(function () {
                let query = Object.assign({}, this.$route.query);
                if (this.searchString.trim()) {
                    query.q = this.searchString;
                } else {
                    delete query.q;
                }
                this.$router.push({ query: query });
            }, 500),
            setStatus() {
                this.message = ''
                let query = Object.assign({}, this.$route.query);
                if (this.searchStatus) {
                    query.s = this.searchStatus;
                } else {
                    delete query.s;
                }
                this.pages = 0;
                query.p = this.pages;

                this.$router.push({ query: query });
            },
            loadMore() {
                this.pages++
                let query = Object.assign({}, this.$route.query);
                query.p = this.pages;
                this.$router.push({ query: query });
            }
        },
        // Charger données au charchement de la page
        beforeRouteEnter(to, from, next) {
            const searchString = to.query.q || ''
            const status = to.query.s || 'Registered'
            const pages = to.query.p || 0
            axios.all([
                axios.post(`/api/adminprofiles/search`, { searchString: searchString, status: status, pages: pages }),
                axios.get(`/api/adminprofiles/status-list`)
            ]).then(
                axios.spread((profile, statusList) => {
                    next(vm => vm.setData(profile.data, statusList.data));
                })
            );
        },
        // Rafraichissement des données lorsqu'on change les valeurs dans le moteur de recherche
        beforeRouteUpdate(to, from, next) {
            const searchString = to.query.q || ''
            const status = to.query.s || 'Registered'
            const pages = to.query.p || 0
            // réinitialisation des résultats si la recherche a changé
            //if (to.query.q != from.query.q || to.query.s != from.query.s) {
                this.profiles = []
                this.noMore = false
            //}
            axios.post("/api/adminprofiles/search", { searchString: searchString, status: status, pages: pages }).then(response => {
                this.profiles = this.profiles.concat(response.data);
                if (response.data.length < this.perPage) {
                    this.noMore = true
                }
                next();
            });
        }


    }
</script>