<template>
    <div class="flex-shrink-0">
        <!-- Formulaire d'abonnement et de paiement en ligne -->
        <form class="form-signin mt-5" @submit.prevent="submit">
            <h1 class="h3 mb-3 font-weight-normal">{{ $t("S'abonner") }}</h1>
            <div class="mt-3" v-if="locale=='fr'">
                <p>
                    Vous trouverez sur cette page les différentes formules d’abonnement pour accéder au site.
                    <br />Choisissez la formule qui vous convient le mieux !
                </p>
                <p>Tous les abonnements sont à régler par carte bancaire uniquement.</p>
                <p>
                    Les abonnements n’ont pas de reconduction tacite, autrement dit, ils prennent fin à l’issue du nombre de mois que vous avez sélectionné. <br />
                    Ainsi, vous n’avez aucune surprise.
                </p>
                <p>Les paiements effectués sont non remboursables et non échangeables, même si vous souhaitez mettre fin à votre expérience avant l’expiration de votre abonnement</p>
            </div>
            <div class="mt-3" v-if="locale=='nl'">
                <p>
                    Op deze pagina vindt u de verschillende abonnementsformules om toegang te krijgen tot de site.
                    <br />Kies de formule die het best bij u past!
                </p>
                <p>Alle abonnementen kunnen enkel via betaalkaart worden betaald.</p>
                <p>
                    De abonnementen worden niet automatisch verlengd, dit betekent dat ze beëindigd worden na het aantal maanden dat u hebt gekozen.<br />
                    Zo komt u niet voor verrassingen te staan.
                </p>
                <p>De uitgevoerde betalingen worden niet terugbetaald of geruild, ook al wilt u uw abonnement vroeger beëindigen dan voorzien.</p>
            </div>
            <div class="mt-3" v-if="locale=='en'">
                <p>
                    This page details the various subscription plans we provide to enable users to have access to the website.<br />
                    Select the plan best suited to your personal wishes!
                </p>
                <p>All subscriptions must be paid by bank (debit or credit) card as the sole method of payment.</p>
                <p>
                    The subscriptions are not tacitly renewed. Which means all plans will expire at the end of the number of months chosen by you.<br />
                    This avoids any unpleasant bombshells.
                </p>
                <p>Payments made are non-refundable or non-exchangeable, even if you decide you wish to end your experience early, ahead of the expiry date of your subscription.</p>
            </div>

            <!-- Choix de la formule - boutons radio -->
            <div id="subscribe-products-list">
                <div class="custom-control custom-radio mb-2" v-for="type in subscriptionTypes" :key="type.id">
                    <input type="radio" name="subscriptionType" :id="'type-' + type.id" class="custom-control-input" :value="type.id" @click="setSubscription(type)">
                    <label class="custom-control-label" :for="'type-' + type.id"> {{ type.name.replace('mois', $t('mois_pluriel')) }}: {{ String(type.price/type.monthCount).replace('.',',') }}€/{{$t('Mois').toLowerCase()}} = {{ String(type.price).replace('.',',') }}€</label>
                </div>
            </div>
            <!-- END Choix de la formule - boutons radio -->
            <hr>

            <!-- Formulaire de paiement - Carte de crédit -->
            <div id="subscribe-stripe-form">
                <!--<h5 class="mb-4">{{ $t("Payer par carte de crédit") }}</h5>-->
                
                <div class="alert alert-danger" role="alert" v-if="error">
                    {{ error }}
                </div>
                <button class="btn btn-lg btn-block button-link" type="submit" :disabled="loading">{{ $t("Payer") }} <span v-if="subscriptionType != null">{{ String(subscriptionType.price).replace('.',',') }}€</span> <span v-if="loading" class="fas fa-spinner fa-spin"></span></button>
                <!-- END Formulaire de paiement - Carte de crédit -->
            </div>

            <p class="small mt-3"><a href="#" @click.prevent="logout">{{ $t("Annuler la transaction") }}</a></p>
        </form>
        <!-- END Formulaire d'identification -->

    </div>
</template>

<script>
    import axios from "axios";

    export default {
        name: 'subscription',
        data() {
            return {
                // --- STYLE STRIPE (doc: https://stripe.com/docs/js/appendix/style)
                //style: {
                //    base: {
                //        lineHeight: '37px',
                //        backgroundColor: "#ffa760"
                //    }
                //},
                //// STYLE STRIPE ---
                //stripe: '',
                //elements: '',
                //card: '',
                error: '',

                subscriptionTypes: [],
                subscriptionType: null,
                cardName: '',
                loading: false
            }
        },
        mounted() {
            //this.includeStripe('js.stripe.com/v3/', function () {
            //    this.configureStripe();
            //}.bind(this));
        },
        computed: {
            price() {
                return 0
            },
            locale() {
                return this.$i18n.locale
            },
        },
        watch: {
            /* eslint-disable */
            locale: function (val) {
                this.setData(this.subscriptionTypes)
            }
            /* eslint-enable */
        },
        methods: {
            // Submit
            submit() {
                console.log('submitted')
                this.error = ''
                if (this.subscriptionType == null) {
                    this.error = this.$t("Veuillez sélectionner un abonnement")
                }

                const subscription = {
                    //stripeToken: result.token.id,
                    subscriptionType_Id: this.subscriptionType.id,
                    subscriptionPrice: this.subscriptionType.price
                }
                // Post data
                /* eslint-disable */
                axios
                    .post("/api/subscription/subscribe", subscription)
                    .then(response => {
                        console.log("response=====")
                        console.log(response.data);
                        window.location.href = response.data

                        this.loading = false;
                    })
                    .catch(error => {
                        //process server errors
                        console.log(error.response);
                        this.error = this.$t("Votre paiement a échoué")
                        this.loading = false;
                    });
                /* eslint-enable */
            },
            setData(subscriptionTypes) {
                this.subscriptionTypes = subscriptionTypes;
            },
            setSubscription(type) {
                this.subscriptionType = type
            },
            logout() {
                this.$store.dispatch("logout").then(() => {
                    this.$router.push({
                        name: 'home',
                        params: { locale: this.$i18n.locale }
                    })
                })
            }
        },
        beforeRouteEnter(to, from, next) {
            axios.get("/api/subscription/subscription-types")
                .then(response => {
                    next(vm => vm.setData(response.data));
                })
        },
        beforeUnmount() {
            this.card.destroy();
        }
    }
</script>

<style lang="scss" scoped>
    .StripeElement--focus {
        border-color: #80bdff;
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }
</style>