<template>
    <div v-if="profile">
        <h1>{{profile.pseudo}}</h1>
        <div class="profile-summary mt-2 mb-5">{{profile.description}}</div>
    </div>
</template>
<script>
export default {
    name: "profile-details",
    props: {
        profile: {
            type: Object,
            required:true
        }
    }
};
</script>

<style lang="scss" scoped>
    h1 {text-align:center;  margin-top:40px;}
    @media (min-width: 768px) {
        h1 {
            text-align: left;
            margin-top: 0px;
        }
    }
</style>