<template>
    <div class="card">
        <a :class="{ 'card-header d-flex': true, 'collapsed': !open, 'open': open }" @click="open = !open">
            <div class="block-item-label">Contenus {{ language }}</div>
            <div class="subscriber-item-edit">
                <i class="fas fa-chevron-down float-right"></i>
            </div>
        </a>
        <transition @enter="onEnter" @leave="onLeave">
            <div id="collapseOne" class="collapse" v-if="open">
                <div class="card-body">
                    <div class="form-group row">
                        <label :for="'title'+language_id" class="col-lg-3 field-label">Titre du bloc</label>
                        <div class="col-lg-9">
                            <input type="text" :id="'title'+language_id" class="form-control" placeholder="Titre de mon bloc" required v-model="data.title">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label :for="'subtitle'+language_id" class="col-lg-3 field-label">Sous-titre du bloc</label>
                        <div class="col-lg-9">
                            <textarea class="form-control" :id="'subtitle'+language_id" rows="2" placeholder="Sous-titre facultatif de mon bloc" v-model="data.subTitle"></textarea>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label :for="'headline'+language_id" class="col-lg-3 field-label">Headline</label>
                        <div class="col-lg-9">
                            <ckeditor :editor="editor" v-model="data.headline" :config="editorConfig"></ckeditor>
                            <!--<textarea class="form-control" :id="'headline'+language_id" rows="4" placeholder="Texte de la headline (optionnel)" v-model="data.headline"></textarea>-->
                        </div>
                    </div>
                    <div class="form-group row">
                        <label :for="'label'+language_id" class="col-lg-3 field-label">Label du bouton</label>
                        <div class="col-lg-9">
                            <input type="text" :id="'label'+language_id" class="form-control" v-model="data.buttonLabel">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label :for="'url'+language_id" class="col-lg-3 field-label">URL</label>
                        <div class="col-lg-9">
                            <input type="text" :id="'url'+language_id" class="form-control" placeholder="URL facultative - lié au bouton" v-model="data.buttonLink">
                        </div>
                    </div>
                    <div class="form-group row" v-if="template != 'c'">
                        <label :for="'text'+language_id" class="col-lg-3 field-label">Texte<br /><small>Texte de la page à laquelle on accède via le bouton dont le label est spécifié mais pas le lien.</small></label>
                        <div class="col-lg-9">
                            <!--<textarea class="form-control" :id="'text'+language_id" rows="12" aria-describedby="BlockTextHelpBlock" v-model="data.text"></textarea>-->
                            <ckeditor :editor="editor" v-model="data.text" :config="editorConfig"></ckeditor>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
    import CKEditor from '@ckeditor/ckeditor5-vue';
    import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
    import '@ckeditor/ckeditor5-build-classic/build/translations/fr';
    import { String } from "core-js";
    import * as Velocity from "velocity-animate";

    export default {
        name: "language-content",
        components: {
            // Use the <ckeditor> component in this view.
            ckeditor: CKEditor.component
        },
        props: {
            language: {
                type: String,
                required: true
            },
            template: {
                type: String,
                required: true
            },
            isOpen: {
                isOpen: Boolean
            },
            translation: {
                required: true
            }
        },
        data() {
            return {
                open: false,
                editor: ClassicEditor,
                editorConfig: {
                    language: 'fr',
                    toolbar: ['heading', '|', 'bold', 'italic', '|', 'link', '|', 'numberedList', 'bulletedList', 'outdent', 'indent', 'blockQuote', '|', 'insertTable', 'undo', 'redo'],
                    mediaEmbed: {
                        previewsInData: true
                    }
                }
            }
        },
        computed: {
            data: {
                get() { return this.translation },
                set(data) { this.$emit('input', data) }
            },
            language_id() {
                if (this.translation != null) {
                    return this.translation.language_Id
                }
                return ''
            }
        },
        mounted() {
            this.open = this.isOpen
        },
        methods: {
            // Slide
            onEnter(el, done) {
                Velocity(el, "slideDown", {
                    duration: 200,
                    easing: "ease-in-out",
                    complete: done
                });
            },
            onLeave(el, done) {
                Velocity(el, "slideUp", {
                    duration: 250,
                    easing: "ease-in-out",
                    complete: done
                });
            },
        }
    }
</script>

<style lang="scss" scoped>
    .card-header {
        font-weight: bold;
        cursor: pointer;
        .fa-chevron-down

    {
        position: relative;
        top: 6px;
        transition: all 0.2s ease-in-out;
    }

    &.open {
        .fa-chevron-down

    {
        transform: rotate(180deg);
    }

    }
    }

</style>