<template>
    <div class="chatroom-discussion-flow-day-divider" v-if="differentDate"><span>{{ messagesDate }}</span></div>
    <div :class="{'chatroom-discussion-flow-item':true, 'recipient': isChatter, 'sender': !isChatter}" v-if="differentDateTime || prevSender!=message.userName">
        <div class="chatroom-discussion-flow-item-picture" v-if="message && isChatter"><img :src="picture"></div>
        <div class="chatroom-discussion-flow-item-text">
            <p class="flow-time"><span v-if="isChatter">{{ message.pseudo }}, </span>{{ messageTime }}</p>
            <p class="flow-phylactere" v-html="text" v-if="!isImage"></p>
            <img :src="message.messageText" class="img-fluid showpointer chat-image" @click="openGallery" v-else />
        </div>
    </div>
    <div :class="{'chatroom-discussion-flow-item':true, 'recipient': isChatter, 'sender': !isChatter}" v-else style="margin-top: -32px;">
        <div class="chatroom-discussion-flow-item-picture" v-if="message && isChatter"></div>
        <div class="chatroom-discussion-flow-item-text">
            <p class="flow-phylactere" v-html="text" v-if="!isImage"></p>
            <img :src="message.messageText" class="img-fluid showpointer chat-image" @click="openGallery" v-else />
        </div>
    </div>
</template>

<script>
    export default {
        name: 'conversation',
        props: {
            message: {
                type: Object,
                required: true
            },
            prevDate: {
                required: true
            },
            prevSender: {
                type: String,
                required: true
            },
            targetUserName: {
                type: String,
                required: true
            },
            picture: {
                type: String,
            }
        },
        computed: {
            text() {
                let text = this.message.messageText
                // Emoticon shortcuts
                text = text.replaceAll(':D', '&#128513;')
                text = text.replaceAll(';)', '&#128521;')
                text = text.replaceAll(':p', '&#128523;')
                text = text.replaceAll('-_-', '&#128529;')
                text = text.replaceAll(':/', '&#128533;')
                text = text.replaceAll(':o', '&#128558;')
                text = text.replaceAll(':(', '&#128577;')
                text = text.replaceAll(':)', '&#128578;')
                text = text.replaceAll('<3', '&#128151;')

                return text
            },
            isImage() {
                if (this.message != null) {
                    const msg = this.message.messageText
                    if (msg != null) {
                        if (msg.substr(0, 10) == 'data:image') {
                            return true
                        }
                    }
                }
                return false
            },
            test() {
                return this.prevSender != this.message.userName
            },
            isChatter() {
                return this.message.userName == this.targetUserName
            },
            differentDate() {
                let msgDate = new Date(this.message.messageDate)
                msgDate.setHours(0, 0, 0, 0)
                let prevMsgDate = new Date(this.prevDate)
                prevMsgDate.setHours(0, 0, 0, 0)
                return msgDate.getTime() != prevMsgDate.getTime()
            },
            differentDateTime() {
                let msgDate = new Date(this.message.messageDate)
                msgDate.setSeconds(0)
                msgDate.setMilliseconds(0)
                let prevMsgDate = new Date(this.prevDate)
                prevMsgDate.setSeconds(0)
                prevMsgDate.setMilliseconds(0)
                return msgDate.getTime() != prevMsgDate.getTime()
            },
            messagesDate() {
                let now = new Date();
                now.setHours(0, 0, 0, 0)
                let messageDate = new Date(this.message.messageDate)
                messageDate.setHours(0, 0, 0, 0)
                if (messageDate.getTime() == now.getTime()) {
                    return this.$t("aujourd'hui")
                } else {
                    return this.$filters.date(this.message.messageDate)
                }
            },
            messageTime() {
                let messageDate = new Date(this.message.messageDate)
                let minutes = (messageDate.getMinutes().toString().length == 2) ? messageDate.getMinutes() : '0' + messageDate.getMinutes()
                return messageDate.getHours() + ':' + minutes
            }
        },
        methods: {
            openGallery() {
                this.$parent.$parent.openGallery(this.message.messageText);
            },
        }
    }
</script>

<style>
    .chat-image{
        width:100px;
    }
</style>