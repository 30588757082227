<template>
    <main role="main">
        <div class="bg-darkred" id="navbarMessage" v-if="message">
            <div class="container">
                {{ message }}
            </div>
        </div>
        <div class="container">
            <div class="row">
                <div class="col-md-12 mt-2 mb-3">
                    <a href="javascript:history.back()" class="back-link"><span><i class="fas fa-arrow-left" aria-hidden="true"></i></span>{{ $t('Retour') }}</a>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 mb-5 text-center">
                    <h1>{{ $t("Editer mon profil") }}</h1>
                </div>
            </div>

            <div class="row">
                <!-- Colonne de gauche  -->
                <div class="col-md-3">
                    <!-- photo du profil  -->
                    <profile-picture :picture="profile.user.picture" :newPicture="profile.user.newPicture" :pseudo="profile.user.pseudo" :slug="profile.user.slug" v-if="profile" @input="profile.user.newPicture = $event"></profile-picture>
                </div>
                <!-- END Colonne de gauche  -->

                <div class="col-md-9">
                    <form>
                        <div class="mb-5">
                            <div class="container">

                                <!-- FORMULAIRE PRINCIPAL -->
                                <user v-if="userData" :userData="userData" :subscriptionTypes="subscriptionTypes" @error="isZipOrPwError = $event" :errors="errors"></user>

                                <!-- Photos supplémentaires -->
                                <pictures :userPictures="profile.user.userPictures" :slug="profile.user.slug" v-if="profile" @delete-picture-code="deletePictureCode($event)" @delete="profile.user.oldPictures = $event" @add="profile.user.newPictures = $event"></pictures>
                            </div>
                        </div>

                        <!-- TABS PHYSIQUE, PERSONNALITE, MENTAL, SPIRITUALITE -->
                        <user-fields :userFieldChoices="userFieldChoices" :fieldChoices="fieldChoices" v-if="fieldChoices"></user-fields>

                        <!-- Boutons -->
                        <div class="alert alert-danger" role="alert" v-if="isError || isZipOrPwError">
                            {{ $t("Veuillez vérifier la validité du formulaire") }}
                            <ul v-if="errors">
                                <li v-for="error in errors" :key="error">{{ error }}</li>
                            </ul>
                        </div>
                        <div class="col-md-12 text-right mt-4 mb-5">
                            <!--<a class="btn btn-secondary" href="profil.html">voir le profil</a>-->
                            <a class="btn btn-primary" href="#" @click.prevent="saveData">{{ $t("Enregistrer mes modifications") }}</a>
                        </div>
                    </form>
                </div>

            </div>
        </div>

    </main>
</template>

<script>
    import User from "@/components/profile/UserEdit.vue"
    import UserFields from "@/components/shared/profileEdit/Fields.vue"
    import ProfilePicture from "@/components/shared/profileEdit/ProfilePicture.vue"
    import Pictures from "@/components/shared/profileEdit/Pictures.vue"
    import axios from "axios";

    export default {
        name: "profile-edit",
        components: {
            User,
            UserFields,
            ProfilePicture,
            Pictures
        },
        data() {
            return {
                profile: null,
                userData: null,
                fieldChoices: null,
                userFieldChoices: [],
                subscriptionTypes: [],
                isZipOrPwError: false,
                isError: false,
                errors: {},
                // pseudo, la photo de profil, localisation, âge, taille, valeurs, statut (marié, célibataire,...), enfants (oui/non) et un trait de son caractère
                requiredFields: ['firstName', 'lastName', 'pseudo', 'email', 'gender', 'birthDate', 'zipCode','description'],
                requiredChoices: ['height', 'character', 'status', 'children', 'values'],
                message: null
            };
        },
        methods: {
            setData(profile, fieldChoices, subscriptionTypes) {
                this.profile = profile;
                this.userData = profile.user;
                this.userFieldChoices = profile.userFieldChoices;

                this.fieldChoices = fieldChoices;
                this.subscriptionTypes = subscriptionTypes;
            },
            validateForm() {
                this.isError = false
                let i = 0
                this.errors = {};
                // validate picture
                if ((this.userData.picture == null || this.userData.picture == '') && (this.userData.newPicture == null || this.userData.newPicture == '')) {
                    this.isError = true
                    this.errors['picture'] = this.$t('fields.required', { field: "'" + this.$t('Photo de profil') + "'" })
                }

                // validate fields
                for (i; i < this.requiredFields.length; i++) {
                    let field = this.requiredFields[i]
                    let fieldValue = this.profile.user[field]
                    if (fieldValue == null || fieldValue.trim() == '' || fieldValue == '0') {
                        this.isError = true
                        let fieldTrans = "'" + this.$t('fields.' + field) + "'"
                        let msg = this.$t('fields.required', { field: fieldTrans })
                        this.errors[field] = msg
                    }
                }
                // validate tab choices
                for (i = 0; i < this.requiredChoices.length; i++) {
                    const field = this.requiredChoices[i]
                    let userField = this.userFieldChoices.filter(x => x.field_Slug == field)
                    if (userField != undefined) {
                        if (userField[0].choices.length == 0 || userField[0].choices[0] == '0') {
                            this.isError = true
                            let tab = ''
                            if (field == 'height') {
                                tab = this.$t('physique').toUpperCase() + '/'
                            } else if (field == 'character') {
                                tab = this.$t('personnalité').toUpperCase() + '/'
                            } else if (field == 'status' || field == 'children') {
                                tab = this.$t('mental').toUpperCase() + '/'
                            } else if (field == 'values') {
                                tab = this.$t('spiritualité').toUpperCase() + '/'
                            }
                            let fieldTrans = "'" + tab + this.$t('fields.f-' + field) + "'"

                            let msg = this.$t('fields.required', { field: fieldTrans })
                            this.errors[field] = msg
                        }
                    }
                }
            },
            isUnique(field) {
                return new Promise((resolve) => {
                    if (this.userData[field] != '') {
                        axios.get(`/api/profiles/${field.toLowerCase()}-exists/${this.userData[field]}/${this.userData.id}`)
                            .then(
                                response => {
                                    if (response.data) {
                                        let msg = this.$t('fields.unique', { value: this.userData[field] })
                                        this.errors[field] = msg
                                        this.isError = true
                                    }
                                    resolve('done')
                                }
                            )
                    }
                    resolve('done')
                })
            },
            saveData() {
                /* eslint-disable */
                this.validateForm()
                // email unique
                this.isUnique('email').then(
                    // username unique
                    result => this.isUnique('userName').then(
                        result => this.storeData()
                    )
                )
                /* eslint-enable */
            },
            storeData() {
                if (!this.isZipOrPwError && !this.isError) {
                    this.profile.user = this.userData;
                    const locale = this.$i18n.locale
                    const slug = this.userData.slug
                    /* eslint-disable */
                    axios.post("/api/profiles/create-update", this.profile)
                        .then(
                            response => {
                                //const locale = this.$i18n.locale
                                //const status = this.userData.status
                                if (this.$store.getters.isInRole('IncompleteSubscriber') || this.$store.getters.isInRole('IncompleteGuest')) {
                                    this.$store.dispatch("completeSubscriber")
                                }

                                this.$router.replace({ name: 'profile', params: { locale, slug }, query: { u: 'ok' } })
                            }
                        )
                        .catch(error => {
                            //TO DO: handle server side validation
                            //console.log(error.data);
                        });
                    /* eslint-enable */
                }
            },
            deletePictureCode(pictureCode) {
                var index = this.userData.newPictures.indexOf(pictureCode);
                if (index !== -1) {
                    this.userData.newPictures.splice(index, 1);
                }
            }
        },
        mounted() {
            if (this.$route.query.subscrok == 1) {
                window.scrollTo(0, 0);
                this.message = this.$t("Profil incomplet")
            }
            //let ref = this
            //axios.get(`/api/reports/history/${this.profile.id}`)
            //    .then(response => {
            //        ref.history = response.data;
            //    })
        },
        //computed: {
        //    reportings() {
        //        return this.history.filter(h => h.interactionType_Id == 5)
        //    },
        //    blockings() {
        //        return this.history.filter(h => h.interactionType_Id == 4)
        //    }
        //},
        beforeRouteEnter(to, from, next) {
            axios.all([
                axios.get(`/api/profiles/edit`),
                axios.get(`/api/profiles/field-choices`),
                axios.get(`/api/subscription/subscription-types`),
            ]).then(
                axios.spread((profile, fieldChoices, subscriptionTypes) => {
                    next(vm => vm.setData(profile.data, fieldChoices.data, subscriptionTypes.data));
                })
            );
        }

    }
</script>