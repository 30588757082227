import { createWebHistory, createRouter } from "vue-router";

import { setI18nLanguage, loadLocaleMessages, SUPPORT_LOCALES } from '../i18n'
import NProgress from 'nprogress';

// FRONT
// Public
import Home from "@/pages/Home.vue"
import Register from "@/pages/AccountRegister.vue"
import Login from "@/pages/AccountLogin.vue"
import LoginCode from "@/pages/AccountLoginCode.vue"
import ForgotPassword from "@/pages/AccountForgotPassword.vue"
import ResetPassword from "@/pages/AccountResetPassword.vue"

// Authentified
import Subscription from "@/pages/Subscription.vue"
import SubscriptionSuccess from "@/pages/SubscriptionSuccess.vue"

import HomeConnected from "@/pages/HomeConnected.vue"
import Search from "@/pages/Search.vue"
import Visits from "@/pages/ProfileVisits.vue"
import Likes from "@/pages/ProfileLikes.vue"
import Online from "@/pages/Online.vue"
import ProfileEdit from "@/pages/ProfileEdit.vue"
import Profile from "@/pages/Profile.vue"
import Events from "@/pages/Events.vue"
import Page from "@/pages/ContentPage.vue"
import Chat from "@/pages/Chat.vue"
// ADMIN
import Preregistered from "@/pages/admin/Preregistered.vue"
import AdminProfiles from "@/pages/admin/Profiles.vue"
import AdminProfileEdit from "@/pages/admin/AdminProfileEdit.vue"
import Validation from "@/pages/admin/Validation.vue"
import Reports from "@/pages/admin/Reports.vue"
import ContentPlaces from "@/pages/admin/ContentPlaces.vue"
import Contents from "@/pages/admin/Contents.vue"
import ContentEdit from "@/pages/admin/ContentEdit.vue"

import { store }  from '../store'

// persistance du store
import axios from "axios";
const initialStore = localStorage.getItem("store");
if (initialStore) {
    console.log('*** initialize store')
    store.commit("initialize", JSON.parse(initialStore));
    if (store.getters.isAuthenticated) {
        axios.defaults.headers.common["Authorization"] = `Bearer ${store.state.auth.access_token}`;
    }
}

export function setupRouter(i18n) {
    const locale =
        i18n.mode === 'legacy' ? i18n.global.locale : i18n.global.locale.value

    //let defaultRedirect = `/${locale}`;
    //if (store.getters.isAuthenticated) { defaultRedirect += '/homepage' }

    // setup routes
    const routes = [
        // FRONTOFFICE
        // public
        {
            path: '/:locale',
            name: 'home',
            component: Home
        },
        {
            path: '/:locale/home',
            name: 'home2',
            component: Home
        },
        {
            path: '/:locale/register',
            name: 'register',
            component: Register
        },
        {
            path: '/:locale/login',
            name: 'login',
            component: Login
        },
        {
            path: '/:locale/login-code',
            name: 'login-code',
            component: LoginCode
        },
        {
            path: '/:locale/forgot-password',
            name: 'forgot-password',
            component: ForgotPassword
        },
        {
            path: '/:locale/reset-password',
            name: 'reset-password',
            component: ResetPassword
        },
        {
            path: '/:locale/page/:slug',
            name: 'page',
            component: Page
        },
        // authenticated
        {
            path: '/:locale/subscription',
            name: 'subscription',
            component: Subscription,
            meta: { requiresAuth: true }
        },
        {
            path: '/:locale/subscription_success/:token',
            name: 'subscription-success',
            component: SubscriptionSuccess,
            meta: { requiresAuth: true }
        },
        {
            path: '/:locale/homepage',
            name: 'home-connected',
            component: HomeConnected,
            meta: {requiresAuth: true}
        },
        {
            path: '/:locale/search',
            name: 'search',
            component: Search,
            meta: { requiresAuth: true }
        },
        {
            path: '/:locale/visits',
            name: 'visits',
            component: Visits,
            meta: { requiresAuth: true }
        },
        {
            path: '/:locale/likes',
            name: 'likes',
            component: Likes,
            meta: { requiresAuth: true }
        },
        {
            path: '/:locale/online',
            name: 'online',
            component: Online,
            meta: { requiresAuth: true }
        },
        {
            path: '/:locale/profiles/:slug',
            name: 'profile',
            component: Profile,
            meta: {requiresAuth: true}
        },
        {
            path: '/:locale/profile-edit',
            name: 'profile-edit',
            component: ProfileEdit,
            meta: {requiresAuth: true}
        },
        {
            path: '/:locale/events',
            name: 'events',
            component: Events,
            meta: { requiresAuth: true }
        },
        {
            path: '/:locale/info/:slug',
            name: 'info',
            component: Page,
            meta: { requiresAuth: true }
        },
        {
            path: '/:locale/chat',
            name: 'chat',
            component: Chat,
            meta: { requiresAuth: true }
        },
        {
            path: '/:locale/chat/:username',
            name: 'chat-to',
            component: Chat,
            meta: { requiresAuth: true }
        },
        // ---BACKOFFICE ---
        // -----------------
        {
            path: '/:locale/admin/preregistered',
            name: 'admin-preregistered',
            component: Preregistered,
            meta: { requiresAuth: true, role: "Admin" }
        },
        {
            path: '/:locale/admin/profiles',
            name: 'admin-profiles',
            component: AdminProfiles,
            meta: { requiresAuth: true, role: "Admin" }
        },
        {
            path: '/:locale/admin/profile-create',
            name: 'admin-profile-create',
            component: AdminProfileEdit,
            meta: { requiresAuth: true, role: "Admin" }
        },
        {
            path: '/:locale/admin/profile-edit/:slug',
            name: 'admin-profile-edit',
            component: AdminProfileEdit,
            meta: { requiresAuth: true, role: "Admin" }
        },
        {
            path: '/:locale/admin/validation',
            name: 'admin-validation',
            component: Validation,
            meta: { requiresAuth: true, role: "Admin" }
        },
        {
            path: '/:locale/admin/reports',
            name: 'admin-reports',
            component: Reports,
            meta: { requiresAuth: true, role: "Admin" }
        },
        {
            path: '/:locale/admin/content-places',
            name: 'admin-content-places',
            component: ContentPlaces,
            meta: { requiresAuth: true, role: "Admin" }
        },
        {
            path: '/:locale/admin/contents/:slug',
            name: 'admin-contents',
            component: Contents,
            meta: { requiresAuth: true, role: "Admin" }
        },
        {
            path: '/:locale/admin/content-create',
            name: 'admin-content-create',
            component: ContentEdit,
            meta: { requiresAuth: true, role: "Admin" }
        },
        {
            path: '/:locale/admin/content-edit/:id',
            name: 'admin-content-edit',
            component: ContentEdit,
            meta: { requiresAuth: true, role: "Admin" }
        },
        // Default
        {
            path: '/:pathMatch(.*)*',
            redirect: () => `/${locale}`
        }
    ]

    // create router instance
    const router = createRouter({
        history: createWebHistory(),
        routes
    })

    // navigation guards
    router.beforeEach(async (to, from, next) => {
        // I18n
        const paramsLocale = to.params.locale
        // use locale if paramsLocale is not in SUPPORT_LOCALES
        if (!SUPPORT_LOCALES.includes(paramsLocale)) {
            return next(`/${locale}`)
        }

        // load locale messages
        if (!i18n.global.availableLocales.includes(paramsLocale)) {
            await loadLocaleMessages(i18n, paramsLocale)
        }

        // set i18n language
        setI18nLanguage(i18n, paramsLocale)

        NProgress.start()

        // Authentification
        if (store.getters.isAuthenticated && store.getters.isBackoffice && (to.path == `/${locale}` || to.path == `/${locale}/homepage`)) {
            // redirect to backoffice if user is authentified && is backoffice
            return next(`/${locale}/admin/profiles`)
        } else if (store.getters.isAuthenticated && to.path == `/${locale}`) {
            // redirect to authenticated home if user is authentified
            return next(`/${locale}/homepage`)
        } else if (to.meta.requiresAuth) {
            if (!store.getters.isAuthenticated) {
                return next(`/${locale}`)
            } else {
                if (to.meta.role && store.getters.isInRole(to.meta.role)) {
                    // role ok
                    return next()
                } else if (!to.meta.role) {
                    // pas de rôle nécessaire
                    return next()
                } else {
                    return next(`/${locale}/homepage`)
                }
            }
        } else {
            // auth non requise
            return next()
        }

        //return next()
    })

    router.afterEach(() => {
        NProgress.done()
    })

    return router
}
