<template>
    <div class="custom-select" :tabindex="tabindex" @blur="open = false">
        <div class="selected" :class="{ open: open }" @click="open = !open">
            {{ (unit)? selected + ' ' + unit : selected }}
        </div>
        <div class="items" :class="{ selectHide: !open }">
            <div v-for="(option, i) of options"
                 :key="i"
                 @click="
          selected = option;
          open = false;
          $emit('input', option);
        ">
                {{ (unit)? option + ' ' + unit : option }}
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            options: {
                type: Array,
                required: true,
            },
            selectid: {
                type: String,
                required: false,
                default: null,
            },
            tabindex: {
                type: Number,
                required: false,
                default: 0,
            },
            unit: {
                type: String,
                default: ''
            }
        },
        data() {
            return {
                selected: this.selectid
                    ? this.selectid
                    : this.options.length > 0
                        ? this.options[0]
                        : null,
                open: false,
            };
        },
        mounted() {
            this.$emit("input", this.selected);
        },
        watch: {
            selectid: function (newVal) { 
                this.selected = newVal
            }
        }
    };
</script>

<style scoped>
    .custom-select {
        position: relative;
        width: auto;
        text-align: left;
        outline: none;
        height: 38px;
        line-height: 38px;
        padding: 0;
        background: url("/images/select-arrow-down.png") rgba(255, 167, 96, 1) no-repeat calc(100% - 10px) !important;
        border: 0;
        font-size:.9em;
    }

    #search-filters-settings-forms .custom-select, #online-search-filters-settings .custom-select {
        height: 26px;
        line-height: 26px;
    }

    .custom-select .selected {
        border-radius: 0.25rem;
        border: 0;
        border-bottom: 1px solid rgb(168,1,35);
        color: rgb(168,1,35);
        cursor: pointer;
        user-select: none;
        text-align: center;
        padding: 0 32px 0 10px;
        font-size: .9em;
    }

    .custom-select:focus {
        outline: none;
        box-shadow: 0 0 0 4px rgba(168,1,35,0.4);
    }

    .custom-select:focus .selected {
        border-bottom:0;
    }

    .custom-select .selected.open {
        background: url("/images/select-arrow-up.png") rgba(255, 167, 96, 1) no-repeat calc(100% - 10px) !important;
    }


    .custom-select .items {
        color: #fff;
        border-radius: 0px 0px 6px 6px;
        box-shadow: 0 0 0 4px rgba(168,1,35,0.4);
        overflow: hidden;
        position: absolute;
        background-color: rgba(243, 127, 34, 1);
        left: 0;
        right: 0;
        z-index: 1;
        margin-top: 3px;
        max-height: 140px;
        overflow-y: scroll;
    }

    .custom-select .items div {
        color: #fff;
        padding-left: .5em;
        cursor: pointer;
        user-select: none;
    }

    .custom-select .items div:hover {
        background-color: rgb(168,1,35);
    }

    .selectHide {
        display: none;
    }
</style>