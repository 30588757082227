<template>
	<main role="main">
		<div class="bg-darkred" id="navbarMessage" v-if="messageSuccess">
			<div class="container">
				{{ messageSuccessTranslation }}
			</div>
		</div>
		<div v-if="contents">
			<content-display v-for="content in contents" :key="content.id" :content="content"></content-display>
		</div>

		<!-- Carousel Nouveaux inscrits  -->
		<div class="bg-principe-block carousel-slider mb-1" id="NewSubscribers">
			<div class="container">
				<div class="row">
					<h2>{{ $t("Nouveaux inscrits") }}</h2>
					<div id="carouselNewsubscribers">
						<profile-carousel :profiles="newProfiles" v-if="newProfiles.length"></profile-carousel>
					</div>

				</div>
			</div>
		</div>
		<!-- END Carousel Nouveaux inscrits  -->
		<!-- Carousel Nouveaux inscrits dans votre région  -->
		<div class="carousel-slider mb-1" id="NewLocalSubscribers" v-if="nearProfiles.length">
			<div class="container">
				<div class="row">
					<h2>{{ $t("Nouveaux inscrits dans votre région") }}</h2>
					<div id="carouselNewLocalsubscribers">
						<profile-carousel :profiles="nearProfiles"></profile-carousel>
					</div>
				</div>
			</div>
		</div>
		<!-- END Carousel Nouveaux inscrits dans votre région  -->
		<!-- Carousel Utilisateurs en ligne  -->
		<div class="carousel-slider mb-1 bg-principe-block " id="OnlineSubscribers" v-if="onlineProfiles.length">
			<div class="container">
				<div class="row">
					<h2>{{ $t("Utilisateurs en ligne") }}</h2>
					<div id="carouselOnlineSubscribers" class="carousel slide mb-5" data-ride="carousel" data-interval="false">
						<profile-carousel :profiles="onlineProfiles"></profile-carousel>
					</div>
				</div>
			</div>
		</div>
		<!-- END Carousel Utilisateurs en ligne  -->

	</main>
</template>

<script>
	import axios from "axios";
	import ContentDisplay from "@/components/shared/content/ContentDisplay.vue"
	import ProfileCarousel from "@/components/shared/ProfileCarousel.vue"

	export default {
		name: "homepage-connected",
		components: {
			ContentDisplay,
			ProfileCarousel
		},
		data() {
			return {
				locale: this.$i18n.locale,
				contents: [],
				profiles: [],
				messageSuccess: '',
			}
		},
		mounted() {
			var ref = this
			this.$store.getters.getConnection.on("Connected", function (username, isConnected) {
				const user = ref.profiles.filter(p => p.userName == username)
				if (user != undefined && user != null) {
					user[0].isOnline = isConnected;
				}
			})
			if (this.$route.query.subscrok == 1) {
				window.scrollTo(0, 0);
				this.messageSuccess = "confirm_paiement"
			}
		},
		computed: {
			newProfiles() {
				return this.profiles.filter(p => p.type === 'new')
			},
			nearProfiles() {
				return this.profiles.filter(p => p.type === 'near')
			},
			onlineProfiles() {
				return this.profiles.filter(p => p.type === 'online')
			},
			messageSuccessTranslation() {
                return this.$t(this.messageSuccess)
            }
		},
		methods: {
			setData(contents, profiles) {
				this.contents = contents.value;
				this.profiles = profiles;
			},
			setContent(contents) {
				this.contents = contents.value;
			},
		},
		// Chargement initial
		beforeRouteEnter(to, from, next) {
			axios
				.all([
					axios.get(`/api/contents/place-contents/${to.params.locale}/auth-home`),
					axios.get("/api/homeconnected/carousel-profiles")
				])
				.then(
					axios.spread((contents, profiles) => {
						next(vm => vm.setData(contents.data, profiles.data));
					})
				);
			//axios.get(`/api/contents/place-contents/${to.params.locale}/auth-home`)
			//	.then(response => {
			//		next(vm => vm.setData(response.data));
			//	});
		},
		// Rafraîchir au changement de langue
		beforeRouteUpdate(to, from, next) {
			axios.get(`/api/contents/place-contents/${to.params.locale}/auth-home`)
				.then(response => {
					this.setContent(response.data);
					next();
				});
		}
	};
</script>

<style lang="scss">
    figure.media {
        width: 100%;
        margin: 0 auto;
    }

        figure.media div {
            margin: 0 auto;
        }

    iframe {
        width: 400px;
        margin: 0 auto;
        display: block;
        border-style: none;
    }
</style>