<template>
   <main role="main">
		
		<div class="pt-5 pb-2" @click="hideTabs">
			<div class="container">
				<div class="row">
					<div class="col-md-12 text-center mb-2">
						<h1>{{ $t("Recherche") }}</h1>
					</div>
				</div>
			</div>
  		</div>
		
		<!-- Moteur de recherche - Résumé des critères -->
		<div id="search-filters-summary" v-if="search && showResetAll">
			<div class="container">
				<div class="row">
					<div class="col-md-12 mb-2 mt-2">
						<h4>{{ $t("Vos critères") }}</h4>
					</div>
					<div class="d-inline-block w-100 mb-3">
						<div class="col-md-12">
							<div id="search-filters-summary-inner" class="d-flex">

								<search-summary :fieldsChoices="fieldsChoices" :search="search" />

								<div id="search-more-filters">
									<a class="button-link" href="#" @click.prevent="resetAll" v-if="showResetAll">{{ $t("Tout effacer") }}</a><br />
									<a class="button-link" @click="showHideTabs" v-if="!show">{{ $t("Plus de critères") }}</a>
									<a class="button-link" @click="showHideTabs" v-else>{{ $t("Cacher les critères") }}</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- END Moteur de recherche - Résumé des critères -->
		
		<!-- Moteur de recherche - Formulaires de paramétrage des critères de recherche -->
		<div id="search-filters-settings-container" v-if="search">
			<!-- Zone positionnée en 'absolute' qui vient en overlay sur la grille de profils -->
			<transition name="slide">
				<div id="search-filters-settings" v-show="show" v-bind:class="{ active: show}">
					<div class="container">
						<div class="row">
							<div class="col-md-12">
								<div id="search-filters-settings-inner" class="mt-3">
									<!-- Tab sélection -->
									<nav id="search-filters-settings-nav" class="nav nav-pills">
										<a :class="{'nav-item':1, 'nav-link':1, active: activeTab==='quick-search'}" href="#" @click.prevent="setTab('quick-search')">
											<div class="search-nav-logo"><img src="../../public/images/picto-tab-quicksearch.svg" /></div><div class="search-nav-label">{{ $t('Recherche rapide') }}</div>
										</a>
										<a :class="{'nav-item':1, 'nav-link':1, active: activeTab==='physical'}" href="#" @click.prevent="setTab('physical')">
											<div class="search-nav-logo"><img src="../../public/images/picto-tab-physique.svg" /></div><div class="search-nav-label">{{ $t('physique') }}</div>
										</a>
										<a :class="{'nav-item':1, 'nav-link':1, active: activeTab==='emotional'}" href="#" @click.prevent="setTab('emotional')">
											<div class="search-nav-logo"><img src="../../public/images/picto-tab-emotionnel.svg" /></div><div class="search-nav-label">{{ $t('personnalité') }}</div>
										</a>
										<a :class="{'nav-item':1, 'nav-link':1, active: activeTab==='mental'}" href="#" @click.prevent="setTab('mental')">
											<div class="search-nav-logo"><img src="../../public/images/picto-tab-mental.svg" /></div><div class="search-nav-label">{{ $t('mental') }}</div>
										</a>
										<a :class="{'nav-item':1, 'nav-link':1, active: activeTab==='spiritual'}" href="#" @click.prevent="setTab('spiritual')">
											<div class="search-nav-logo"><img src="../../public/images/picto-tab-spiritualite.svg" /></div><div class="search-nav-label">{{ $t('spiritualité') }}</div>
										</a>
									</nav>
									<!-- Tab contents -->
									<div id="search-filters-settings-forms" class="tab-content">
										<form autocomplete="off">
											<div :class="{'tab-pane':1,active: activeTab==='quick-search'}" id="searchsettings1" v-if="activeTab==='quick-search'">
												<tab-quick-search :search="search.quickSearch" />
											</div>
											<div :class="{'tab-pane':1,active: activeTab==='physical'}" id="searchsettings2" v-if="activeTab==='physical'">
												<tab-physical :fields="physicalFields" :userChoices="physicalUserChoices" :rangeFields="search.quickSearch"></tab-physical>
											</div>
											<div :class="{'tab-pane':1,active: activeTab==='emotional'}" id="searchsettings3" v-if="activeTab==='emotional'">
												<tab-emotional :fields="emotionalFields" :userChoices="emotionalUserChoices"></tab-emotional>
											</div>
											<div :class="{'tab-pane':1,active: activeTab==='mental'}" id="searchsettings4" v-if="activeTab==='mental'">
												<tab-mental :fields="mentalFields" :userChoices="mentalUserChoices"></tab-mental>
											</div>
											<div :class="{'tab-pane':1,active: activeTab==='spiritual'}" id="searchsettings5" v-if="activeTab==='spiritual'">
												<tab-spiritual :fields="spiritualFields" :userChoices="spiritualUserChoices"></tab-spiritual>
											</div>
										</form>
									</div>
									<div id="search-filters-submit" class="mb-4" v-if="showResetAll">
										<a data-toggle="collapse" href="#" @click.prevent="viewResults" class="button-link">{{ $t("Voir les résultats") }}</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</transition>
			<!-- END Zone positionnée en 'absolute' qui vient en overlay sur la grille de profils -->
		</div>
		<!-- END Moteur de recherche - Formulaires de paramétrage des critères de recherche -->

        <!-- Résultats -->
		<div id="search-results" class="mt-5 pt-3 bg-principe-block main-search">
			<div class="container">
				<div class="row">
					<div class="col-md-12 mb-2">
						<h2 class="text-center mb-4">{{ $t("Profils correspondants") }}</h2>
					</div>
					<div class="profiles-grid d-flex">
						<profile-bloc v-for="profile in profiles" :key="profile.userName" :profile="profile"></profile-bloc>
					</div>
					<div class="col-md-12 text-center mt-4 mb-2">
						<a class="btn btn-secondary" @click="showMore" v-if="isMore">{{ $t('Plus de profils') }}</a>
					</div>
				</div>
			</div>
		</div>
		<!-- END Résultats -->
		

	</main> 
</template>

<script>
	import axios from "axios";
	import SearchSummary from "@/components/search/SearchSummary.vue"
	import TabQuickSearch from "@/components/search/TabQuickSearch.vue"
	import TabPhysical from "@/components/search/TabPhysical.vue"
	import TabEmotional from "@/components/search/TabEmotional.vue"
	import TabMental from "@/components/search/TabMental.vue"
	import TabSpiritual from "@/components/search/TabSpiritual.vue"
	import ProfileBloc from "@/components/shared/ProfileBloc"
	export default {
		name: "search",
		components: {
			SearchSummary,
			TabQuickSearch,
			TabPhysical,
			TabEmotional,
			TabMental,
			TabSpiritual,
			ProfileBloc
		},
		data() {
			return {
				show: false,
				activeTab: 'quick-search',
				fieldsChoices: null,
				search: null,
				profiles: [],
				isMore: false
			}
		},
		computed: {
			showResetAll() {
				let show = this.search.quickSearch.gender.length > 0
					|| this.search.quickSearch.distance < 200
					|| this.search.quickSearch.minAge > 18 || this.search.quickSearch.maxAge < 99
                    || this.search.quickSearch.minHeight > 100 || this.search.quickSearch.maxHeight < 250
                    || this.search.quickSearch.minWeight > 35 || this.search.quickSearch.maxWeight < 200
					|| this.search.quickSearch.pseudo != null && this.search.quickSearch.pseudo.trim() != ''
					|| this.search.quickSearch.isOnline
					|| this.search.userSearches.filter(c => c.choices.length > 0).length > 0;
				return show;
			},
			physicalFields() {
				return this.fieldsChoices.filter(c => c.category === 'physical')[0].fields
			},
			physicalUserChoices() {
				return this.search.userSearches.filter(c => c.category === 'physical')
			},

			emotionalFields() {
				return this.fieldsChoices.filter(c => c.category === 'emotional')[0].fields
			},
			emotionalUserChoices() {
				return this.search.userSearches.filter(c => c.category === 'emotional')
			},

			mentalFields() {
				return this.fieldsChoices.filter(c => c.category === 'mental')[0].fields
			},
			mentalUserChoices() {
				return this.search.userSearches.filter(c => c.category === 'mental')
			},

			spiritualFields() {
				return this.fieldsChoices.filter(c => c.category === 'spiritual')[0].fields
			},
			spiritualUserChoices() {
				return this.search.userSearches.filter(c => c.category === 'spiritual')
			},
		},
		methods: {
			viewResults() {
                if (this.showResetAll) {
                    this.show = false
                    this.getProfiles()
                }
			},
			getProfiles() {
				//console.log('getprofiles')
				axios.post(`/api/search`, this.search)
					.then(response => {
						this.setProfiles(response.data);
					});
			},
			setTab(tab) {
				this.activeTab = tab
			},
			setData(data) {
				this.fieldsChoices = data.fieldsChoices;
				this.search = data.search;
				this.profiles = data.searchResult.profileBlocs;
				this.isMore = data.searchResult.isMore;
				if (!this.showResetAll) {
					this.show = true
                }
			},
			setProfiles(searchResult) {
				this.profiles = searchResult.profileBlocs
				this.isMore = searchResult.isMore;
			},
			resetAll() {
				//console.log('reset')
				this.search.quickSearch.gender = []
				this.search.quickSearch.distance = 200
				this.search.quickSearch.minAge = 18
				this.search.quickSearch.maxAge = 99
				this.search.quickSearch.minHeight = 100
				this.search.quickSearch.maxHeight = 250
				this.search.quickSearch.minWeight = 35
				this.search.quickSearch.maxWeight = 200
				this.search.quickSearch.pseudo = ''
				this.search.quickSearch.isOnline = false
				let i = 0
				for (i = 0; i < this.search.userSearches.length; i++) {
					this.search.userSearches[i].choices = []
				}
				this.show = true
				this.getProfiles()
			},
			showHideTabs() {
				if (this.show) {
					this.getProfiles()
				}
				this.show = !this.show
			},
			hideTabs() {
                this.show = !this.show
                this.getProfiles()
			},
			showTabs() {
				this.show = true
            },
			showMore() {
				this.search.page++
				this.getProfiles()
			}
		},
		beforeRouteEnter(to, from, next) {
			axios.get("/api/search/get-data")
				.then(response => {
					next(vm => vm.setData(response.data));
				})
			//.then(response => {
			//	return response.json();
			//})
			//.then(data => {
			//	next(vm => vm.setData(data));
			//});
		},
		//  methods: {
		//reset(){

		//},

		// mounted() {
		//     fetch("/api/profiles")
		//     .then(response => {
		//         return response.json();
		//     })
		//     .then(profiles => {
		//         this.profiles = profiles
		//     });
		// }
	};
</script>

<style>
    .bg-form-block {
        overflow: hidden;
    }
    .slide-enter-active {
        transition: max-height .5s ease;
        max-height: 0;
    }
    .slide-leave-active {
        transition: all .3s cubic-bezier(0, 1, 0.5, 1);
        max-height: 700px;
    }
    .slide-enter, .slide-leave-to {
        overflow: hidden;
        max-height: 0;
    }
    .slide-enter-to, .slide-leave {
        overflow: hidden;
        max-height: 700px;
    }

    @media (min-width: 768px) {
        .slide-leave-active {
            transition: all .3s cubic-bezier(0, 1, 0.5, 1);
            max-height: 450px;
        }
        .slide-enter, .slide-leave-to {
            overflow: hidden;
            max-height: 0;
        }
        .slide-enter-to, .slide-leave {
            overflow: hidden;
            max-height: 450px;
        }
    }
    @media (min-width: 1200px) {
        .slide-leave-active {
            transition: all .3s cubic-bezier(0, 1, 0.5, 1);
            max-height: 380px;
        }
        .slide-enter, .slide-leave-to {
            overflow: hidden;
            max-height: 0;
        }
        .slide-enter-to, .slide-leave {
            overflow: hidden;
            max-height: 380px;
        }
    }

</style>