<template>
    <input type="text" class="form-control"
           :value="value"
           name="zipCode"
           @blur="blur"
           @focus="focus"
           @keydown.enter.prevent="enter"
           @keydown.down.prevent="down"
           @keydown.up.prevent="up"
           @input="input"
           autocomplete="new-password" />

    <div v-if="error" class="err-msg">
        {{ error }}
    </div>

    <ul class="list-group suggestions" v-if="open">
        <li :class="{'list-group-item': true, active: index === current}" v-for="(item, index) in items"
            :key="index"
            @mousedown.prevent="click(item)"
            button>
            {{ item.zipCode + ' ' + item.city }}
        </li>
    </ul>
</template>

<script>
    import axios from "axios";

    export default {
        name: "radio-inlzip-code",
        emits: ["input", "error"],
        props: {
            zipCode: {
                type: String
            },
            city: {
                type: String
            }
        },
        data() {
            return {
                value: '',
                items: [],
                current: -1,
                focused: false,
                locZipCode: '',
                locCity: '',
                error: ''
            }
        },
        mounted() {
            this.locZipCode = this.zipCode
            this.locCity = this.city
            if (this.zipCode != null && this.city != null) {
                this.value = this.zipCode + ' ' + this.city
            }
        },
        computed: {
            open() {
                return (
                    this.items.length > 0
                    &&
                    this.focused &&
                    !this.items.some(item => item.zipCode + ' ' + item.city === this.value)
                );
            }
        },
        methods: {
            input(event) {
                this.value = event.target.value

                if (event.target.value.length > 1) {
                    this.error = ''
                    this.current = -1
                    let val = event.target.value
                    // Get items
                    axios.get(`/api/profiles/zip-cities/${val}`)
                        .then(response => {
                            this.setData(response.data);
                        });
                }
            },
            setData(items) {
                this.items = items
            },
            click(item) {
                this.locZipCode = item.zipCode
                this.locCity = item.city
                this.value = this.locZipCode + ' ' + this.locCity

                this.$emit("input", this.locZipCode);
                this.error = ''
                //this.$refs.input.$el.focus();
            },
            enter() {// hit enter
                if (this.current >= 0) {
                    this.locZipCode = this.items[this.current].zipCode
                    this.locCity = this.items[this.current].city
                    this.value = this.locZipCode + ' ' + this.locCity
                    this.$emit("input", this.locZipCode);
                    this.error = ''
                }
            },
            up() {
                if (this.current >= 0) this.current--;
            },
            down() {
                if (this.current < this.items.length - 1) this.current++;
            },
            focus() {
                this.focused = true;
                //this.$emit("focus");
            },
            blur() {
                this.focused = false;
                //this.$emit("blur");
                if (this.value.length && !this.items.some(item => item.zipCode + ' ' + item.city === this.value)) {
                    this.error = this.$t('Champ non valide')
                    this.$emit("error", true)
                } else {
                    this.error = ''
                    this.$emit("error", false)
                }
            }
        }
    };
</script>

<style lang="scss" scoped>
    .err-msg {
        width: 100%;
        margin-top: .25rem;
        font-size: 80%;
        color: #dc3545
    }
    /*.typeahead {
        position: relative;
        .suggestions
        {
            position: absolute;
            top: 42px;
            right: 0;
            left: 0;
            z-index: 1;
            -webkit-box-shadow: 0px 2px 10px 0px rgba(204, 204, 204, 1);
            -moz-box-shadow: 0px 2px 10px 0px rgba(204, 204, 204, 1);
            box-shadow: 0px 2px 10px 0px rgba(204, 204, 204, 1);
        }
    }*/
</style>