<template>
    <div class="grid-item" v-if="profile">
        <div class="card showpointer" @click="edit(profile)">
            <div class="card-picture" v-if="picture"><img :src="picture" class="card-img-top" :alt="profile.pseudo"></div>
            <div class="card-body">
                <h5 class="card-title stretched-link">{{ profile.pseudo }}</h5>
                <p class="card-text">{{ profile.firstName }} {{ profile.lastName }}<br>{{ profile.email }}<br>{{ profile.status }}</p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "admin-profile-bloc",
        props: {
            profile: {
                type: Object,
            }
        },
        data() {
            return {
                picture: null,
            }
        },
        mounted() {
            let p = this.profile
            if (p.picture == null || p.picture == '') {
                this.setDefaultPicture()
            } else {
                this.picture = p.picture
            }
        },
        methods: {
            edit(profile) {
                this.$router.push(`/${this.$i18n.locale}/admin/profile-edit/${profile.slug}`);
            },
            setDefaultPicture() {
                let p = this.profile
                if (p.gender == 'W') {
                    this.picture = require('@/assets/userPictures/default-w.png')
                } else if (p.gender == 'M') {
                    this.picture = require('@/assets/userPictures/default-m.png')
                } else {
                    this.picture = require('@/assets/userPictures/default-o.png')
                }
            }
        }
    }
</script>