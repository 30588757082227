<template>
    <div class="gallery" @click="close">
        <span id="close_button"><i class="fas fa-times-circle fa-2x" /></span>
        <span @click.stop="prev">
            <i class="fas fa-chevron-circle-left fa-3x prev" />
        </span>
        <div class="slide" @click.stop="next">
            <transition name="fade" mode="out-in">
                <img :src="pictures[index]" :key="pictures[index]" :alt="pictures[index]" />
            </transition>
        </div>
        <span @click.stop="next">
            <i class="fas fa-chevron-circle-right fa-3x next" />
        </span>
    </div>
</template>

<script>
export default {
  name: "gallery",
  props: {
    pictures: {
      type: Array,
      required: true
    },
    initial: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      index: 0
    };
  },
  created() {
    this.index = this.initial;
    window.addEventListener("keyup", this.onKeyup);
  },
  beforeUnmount() {
    window.removeEventListener("keyup", this.onKeyup);
  },
  methods: {
    onKeyup(event) {
      switch (event.keyCode) {
        case 27:
          this.close();
          break;
        case 37:
          this.prev();
          break;
        case 39:
          this.next();
          break;
      }
    },
    next() {
      if (this.index < this.pictures.length - 1) {
        this.index++;
      } else {
        this.index = 0;
      }
    },
    prev() {
      if (this.index > 0) {
        this.index--;
      } else {
        this.index = this.pictures.length - 1;
      }
    },
    close() {
      this.$emit("close");
    }
  }
};
</script>

<style lang="scss" scoped>
    #close_button {
        position: absolute;
        color: white;
        cursor: pointer;
        z-index: 1;
        top: 20px;
        right: 20px;
    }

    .gallery {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.8);
        z-index: 15000;
        .prev, .next

    {
        position: absolute;
        color: white;
        cursor: pointer;
        z-index: 1;
    }

    .prev,
    .next {
        top: 50%;
        transform: translateY(-50%);
    }

    .prev {
        left: 20px;
    }

    .next {
        right: 20px;
    }

    .slide {
        position: relative;
        width: auto;
        max-width: 90%;
        height: 80%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        overflow: hidden;
        display: flex;
        justify-content: center;
        img

    {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        height: 100%;
    }

    }
    }
</style>
