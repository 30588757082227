<template>
    <div :class="colClass" v-if="content">
        <div class="blockcol d-flex flex-column">
            <div class="blockcol-picture" v-if="picture">
                <img :src="picture" class="img-fluid">
            </div>
            <div class="blockcol-data">
                <h4>{{ content.title }}</h4>
                <p v-if="content.headline" v-html="content.headline"></p>
                <div v-if="content.buttonLabel">
                    <a href="#" class="button-link" @click.prevent="goToPage" v-if="content.buttonLink == null || content.buttonLink == ''">{{ content.buttonLabel }}</a>
                    <a :href="content.buttonLink" class="button-link" target="_blank" v-else>{{ content.buttonLabel }}</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from "axios";

    export default {
        name: "template-f",
        props: {
            content: null,
            colClass: String,
        },
        data() {
            return {
                picture: ''
            }
        },
        mounted() {
            this.getPicture()
        },
        methods: {
            getPicture() {
                let pict = this.content.picture
                if (pict != undefined && pict != null && pict != '') {
                    axios.get(`/api/contents/picture/${pict}`)
                        .then(response => {
                            this.picture = response.data
                        });
                }
            },
            goToPage() {
                let action = 'page'
                if (this.content.contentPlace_Slug == 'auth-home' || this.content.contentPlace_Slug == 'events') {
                    action = 'info'
                }
                console.log(`/${this.$i18n.locale}/${action}/${this.content.slug}`)
                this.$router.push(`/${this.$i18n.locale}/${action}/${this.content.slug}`);
            }
        }
    }
</script>