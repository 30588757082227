<template>
    <div id="search-filters-settings-form-4" class="d-flex">
        <div class="settings-form-col w50">
            <div class="form-group">
                <label for="selectJobs" class="field-label">{{ $t('fields.f-status') }}</label>
                <multi-select name="status" :items="statusChoices" :selected="statusUserChoices"></multi-select>
            </div>
        </div>
        <div class="settings-form-col">
            <label class="field-label">{{ $t('fields.f-children') }}</label>
            <div id="children" class="d-flex flex-wrap">
                <radio-inline name="children" :items="childrenChoices" :selected="childrenUserChoice.toString()" @update="childrenUserChoice = $event"></radio-inline>
            </div>
            <label class="field-label">{{ $t('fields.f-morechildren') }}</label>
            <div id="children" class="d-flex flex-wrap">
                <radio-inline name="morechildren" :items="moreChildrenChoices" :selected="moreChildrenUserChoice.toString()" @update="moreChildrenUserChoice = $event"></radio-inline>
            </div>
        </div>
        <div class="settings-form-col">
            <div class="form-group">
                <label for="selectSports" class="field-label">{{ $t('fields.f-languages') }}</label>
                <multi-select name="languages" :items="languagesChoices" :selected="languagesUserChoices"></multi-select>
            </div>
            <div class="form-group mt-3">
                <label for="selectSports" class="field-label">{{ $t('fields.f-education') }}</label>
                <multi-select name="education" :items="educationChoices" :selected="educationUserChoices"></multi-select>

            </div>
        </div>
    </div>
</template>

<script>
    import MultiSelect from "@/components/shared/fields/MultiSelect.vue";
    import RadioInline from "@/components/shared/fields/RadioInline.vue";

    export default {
        name: "tab-mental",
        components: {
            MultiSelect,
            RadioInline
        },
        props: {
            fields: {
                type: Object,
                required: true
            },
            userChoices: {
                type: Object,
                required: true
            }
        },
        computed: {
            statusChoices() {
                return this.fields.filter(c => c.slug === 'status')[0].choices
            },
            statusUserChoices: {
                get() { return this.getUserChoices('status') },
                set(choice) { this.userChoices.filter(c => c.field_Slug === 'status')[0].choices = choice }
            },

            childrenChoices() {
                return this.fields.filter(c => c.slug === 'children')[0].choices
            },
            childrenUserChoice: {
                get() { return this.getUserChoice('children') },
                set(choice) { this.userChoices.filter(c => c.field_Slug === 'children')[0].choices[0] = choice }
            },

            moreChildrenChoices() {
                return this.fields.filter(c => c.slug === 'morechildren')[0].choices
            },
            moreChildrenUserChoice: {
                get() { return this.getUserChoice('morechildren') },
                set(choice) { this.userChoices.filter(c => c.field_Slug === 'morechildren')[0].choices[0] = choice }
            },

            languagesChoices() {
                return this.fields.filter(c => c.slug === 'languages')[0].choices
            },
            languagesUserChoices: {
                get() { return this.getUserChoices('languages') },
                set(choices) { this.userChoices.filter(c => c.field_Slug === 'languages')[0].choices = choices }
            },

            educationChoices() {
                return this.fields.filter(c => c.slug === 'education')[0].choices
            },
            educationUserChoices: {
                get() { return this.getUserChoices('education') },
                set(choice) { this.userChoices.filter(c => c.field_Slug === 'education')[0].choices = choice }
            },
        },
        methods: {
            getUserChoice(slug) {
                let filtered = this.userChoices.filter(c => c.field_Slug === slug)
                if (filtered.length) {
                    let field = filtered[0]
                    if (field.choices.length) {
                        return field.choices[0]
                    }
                }
                return '';
            },
            getUserChoices(slug) {
                let filtered = this.userChoices.filter(c => c.field_Slug === slug)
                if (filtered.length) {
                    return filtered[0].choices
                }
                return []
            },
        }
    }
</script>