<template>
    <main role="main" v-if="data">
        <div class="container">
            <div class="row">
                <div class="col-md-12 mt-2 mb-3">
                    <a href="javascript:history.back()" class="back-link"><span><i class="fas fa-arrow-left"></i></span>{{ $t('Retour') }}</a>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 text-center mb-2">
                    <div id="event-date-header">
                        <div id="event-date-header-category" v-if="data.contentCategory_Slug" :class="[{'event-category':true},data.contentCategory_Slug]"><i class="fas fa-glass-cheers"></i></div>
                        <div id="event-date-header-day" v-if="data.eventStartDate"><h2>{{ $filters.date(data.eventStartDate) }}<span v-if="data.eventEndDate && $filters.date(data.eventStartDate) != $filters.date(data.eventEndDate)"> - {{ $filters.date(data.eventEndDate) }}</span></h2></div>
                    </div>
                    <h1>{{ data.title }}</h1>
                    <h2 v-if="data.subTitle">{{ data.subTitle }}</h2>
                    <h4 v-if="data.eventPrice">{{ data.eventPrice }}</h4>
                </div>
            </div>
        </div>

        <!-- -->
        <div class="py-5">
            <div class="container">
                <div class="row">
                    <!-- Zone visuel -->
                    <div class="col-md-12 text-center" v-if="data.picture">
                        <div class="mb-3">
                            <img :src="data.picture" class="img-fluid page-picture">
                        </div>
                    </div>
                    <!-- Zone rich text -->
                    <div class="col-md-12" v-html="data.text">
                    </div>
                </div>
            </div>
        </div>
        <!--  -->

    </main>
</template>

<script>
    import axios from "axios";

    export default {
        name: "page",
        data() {
            return {
                data: [],
                //picture: ''
            }
        },
        methods: {
            setData(data) {
                this.data = data.value;
                window.scrollTo(0, 0);

            },
        },
        // Chargement initial
        beforeRouteEnter(to, from, next) {
            axios.get(`/api/contents/${to.name}/${to.params.locale}/${to.params.slug}`)
                .then(response => {
                    next(vm => vm.setData(response.data));
                });
        },
        // Rafraîchir au changement de langue
        beforeRouteUpdate(to, from, next) {
            axios.get(`/api/contents/${to.name}/${to.params.locale}/${to.params.slug}`)
                .then(response => {
                    this.setData(response.data);
                    next();
                });
        }
    }
</script>
<style lang="scss">
    iframe {
        width: 300px;
        margin: 0 auto;
        display: block;
        border-style: none;
    }
</style>