<template>
    <label class="radio-inline" v-for="item in items" :key="item.id">
        <input type="radio" :name="name" :value="item.id" v-model="locSelected"> {{ $t('fields.' + name + '.' + item.slug) }}
    </label>
</template>

<script>
    export default {
        name: "radio-inline",
        emits: ["update"],
        props: {
            items: {
                type: Array,
                required: true
            },
            selected: {
                type: String
            },
            name: {
                type: String
            }
        },
        computed: {
            locSelected: {
                get() { return this.selected },
                set(locSelected) { this.$emit('update', locSelected) }
            }
        }
    };
</script>