<template>
	<main role="main">

		<div class="container page" v-if="profile" id="profile-view">
			<div class="row">
				<!-- Colonne de gauche  -->
				<div class="col-md-3" id="profile-side-column">
					<!-- Galerie image des photos du profil  -->
					<transition name="fade" mode="out-in" v-if="profile">
						<gallery v-if="open" :pictures="profile.pictures" :initial="index" @close="open = false" />
					</transition>
					<div id="profile-picture-gallery" v-if="profile">
						<div class="profile-picture-gallery-item" v-for="(picture, index) in profile.pictures" :key="index">
							<img :src="picture" class="img-fluid showpointer" @click="openGallery(index)" />
						</div>
					</div>
					<!-- END Galerie image des photos du profil  -->
					<!-- Boutons like et message -->
					<div id="profile-picture-action-buttons" class="d-flex flex-wrap mt-2" v-if="!profile.noButtons">
						<a href="#" class="btn-social btn-like" @click.prevent="like" v-if="!profile.isLiked"><span><img src="../../public/images/button-like.svg" /></span>{{ $t('Liker') }}</a>
						<a href="#" class="btn-social btn-like liked" @click.prevent="like" v-if="profile.isLiked"><span><img src="../../public/images/button-dislike.svg" /></span>{{ $t('Liker') }}</a>
						<a href="#" class="btn-social btn-message" @click.prevent="goToChat(profile.userName)"><span><img src="../../public/images/button-message.svg" /></span>{{ $t('Message') }}</a>
					</div>
					<!-- END Boutons like et message -->
				</div>
				<!-- END Colonne de gauche  -->

				<div class="col-md-9 mt-5" id="profile-main">
					<!-- Message de confirmation mise à jour profil -->
					<div class="alert alert-success" role="alert" v-if="updateOk">
						{{ $t('Votre profil a bien été mis à jour') }}
					</div>
					<!-- END Message de confirmation mise à jour profil -->

					<!-- Boutons blocage et signalement -->
					<div id="profile-action-buttons" v-if="!profile.noButtons">
						<a href="#" @click.prevent="showBlock = true" v-if="!isBlocked" class="profile-action-button tooltips">
                            <span class="tooltiptexts">{{ $t('Bloquer') }}</span>
							<img src="../../public/images/picto-user-block.svg" alt="{{ $t('Bloquer') }}" id="block_button" />
						</a><br>
						<a href="#" @click.prevent="showReport = true" class="profile-action-button tooltips" v-if="!isReported">
                            <span class="tooltiptexts">{{ $t('Signaler') }}</span>
							<img src="../../public/images/picto-user-claim.svg" alt="{{ $t('Signaler') }}" id="report_button" />
						</a>
					</div>

					<!-- modals -->
					<block-modal :show="showBlock" @update="showBlock = $event" @block="block" />
					<report-modal :show="showReport" @update="showReport = $event" @report="report($event)" />

					<profile-details :profile="profile" v-if="profile" />

					<!-- Points communs -->
					<div id="profile-common-points" class="bg-principe-block mt-5 mb-5 pt-3 pb-3" v-if="profile.commonChoices && profile.commonChoices.length">
						<common-fields :fields="profile.commonChoices"></common-fields>
					</div>

					<field-tabs :fieldChoices="profile.fieldChoices" :age="profile.age" :city="(profile.zipCode != undefined)?profile.zipCode + ' ' + profile.city:''" v-if="profile.fieldChoices && profile.fieldChoices.length"></field-tabs>

				</div>
			</div>
			<div class="row">
				<div class="col-md-12 mt-2 mb-3">
					<a href="javascript:history.back()" class="back-link"><span><i class="fas fa-arrow-left"></i></span>{{ $t('Retour') }}</a>
				</div>
			</div>

		</div>
	</main>
</template>

<script>
	import ProfileDetails from "@/components/profile/Details.vue"
	import Gallery from "@/components/profile/Gallery.vue"
	import CommonFields from "@/components/profile/CommonFields.vue"
	import FieldTabs from "@/components/profile/FieldTabs.vue"
	import BlockModal from "@/components/modals/BlockModal"
	import ReportModal from "@/components/modals/ReportModal"
	import axios from "axios";

	export default {
		name: "profile",
		components: {
			ProfileDetails,
			Gallery,
			CommonFields,
			FieldTabs,
			BlockModal,
			ReportModal
		},
		data() {
			return {
				profile: null,
				open: false,
				index: 0,
				updateOk: false,
				isBlocked: false,
				isReported: false,
				showBlock: false,
				showReport: false,
			}
		},
		mounted() {
			window.scrollTo(0, 0);
		},
		beforeRouteEnter(to, from, next) {
			axios.get(`/api/profiles/${to.params.slug}`)
				.then(response => {
					next(vm => vm.setData(response.data, to.query.u));
				});
		},
		methods: {
			//clickOutsideReport(e) {
			//	if (e.target.id != 'report_button') {
			//		this.showReport = false
			//	}
			//},
			//clickOutsideBlock(e) {
			//	if (e.target.id != 'block_button') {
			//		this.showBlock = false
			//	}
			//},
			setData(profile, updateOk) {
				this.profile = profile;
				// Send profile visit
				if (this.$store.getters.getConnection.connectionState == "Connected") {
					this.$store.getters.getConnection.invoke("SendInteraction", profile.userName, 1);
				}
				if (updateOk == 'ok') {
					this.updateOk = true
				}
			},
			openGallery(index) {
				this.index = index;
				this.open = true;
			},
			like() {
				// Send profile visit
				if (this.$store.getters.getConnection.connectionState == "Connected") {
					this.$store.getters.getConnection.invoke("SendInteraction", this.profile.userName, 2);
					this.profile.isLiked = !this.profile.isLiked;
				}
			},
			goToChat(username) {
				console.log(`/${this.$i18n.locale}/chat/${username}`)
				this.$router.push(`/${this.$i18n.locale}/chat/${username}`);
			},
			block() {
				axios.get(`/api/profiles/block/${this.profile.userName}`)
                this.$router.push(`/${this.$i18n.locale}/homepage?block=${this.profile.pseudo}`)
				//this.isBlocked = true
				//this.showBlock = false
			},
			report(reason) {
                this.isReported = true
				console.log('rapporteur: ' + reason)

                axios.post('/api/profiles/report', { targetUserName: this.profile.userName, reason: reason })
			}
			//unblock() {
			//    axios.get(`/api/profiles/unblock/${this.profile.userName}`)
			//    this.isBlocked = false
			//},
		}
	};
</script>

<style>
	#profile-picture-gallery {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
        width: 300px;
        margin: 0 auto;
	}

	#profile-picture-gallery .profile-picture-gallery-item {
		width: 58px;
		height: 58px;
		margin-right: 2%;
		overflow: hidden;
		margin-bottom: 5px;
		display: inline-block;
		border-radius: 90%;
	}

	#profile-picture-gallery .profile-picture-gallery-item img {
		object-fit: cover;
		background-position: top;
		width: 100%;
		height: 100%;
	}

	#profile-picture-gallery .profile-picture-gallery-item:first-child {
		width: 250px;
		height: 250px;
		border-right: 0;
		margin-right: 0;
		overflow: hidden;
		border-radius: 90%;
	}

	#profile-picture-gallery .profile-picture-gallery-item:nth-child(5), #profile-picture-gallery .profile-picture-gallery-item:nth-child(9) {
		margin-right: 0;
	}

	@media (min-width: 768px) {
        #profile-picture-gallery {width:160px; margin: 0 auto; justify-content: flex-start;}
		#profile-picture-gallery .profile-picture-gallery-item {height:38px; width:38px;}
        #profile-picture-gallery .profile-picture-gallery-item:first-child {height:150px; width:150px;}

	}
	@media (min-width: 996px) {
		#profile-picture-gallery { width:100%;}
		#profile-picture-gallery .profile-picture-gallery-item {height:48px; width:48px;}
        #profile-picture-gallery .profile-picture-gallery-item:first-child {height:200px; width:200px;}
	}
	@media (min-width: 1200px) {
		#profile-picture-gallery { width:100%;}
		#profile-picture-gallery .profile-picture-gallery-item {height:58px; width:58px;}
        #profile-picture-gallery .profile-picture-gallery-item:first-child {height:250px; width:250px;}
	}
</style>