<template>
    <div class="form-group row">
        <label for="height" class="col-lg-3 field-label">{{ $t('fields.f-height') }} (cm) *</label>
        <div class="col-lg-3">
            <custom-select-val-label :options="heightChoices" :selectid="heightUserChoice.toString()" @input="heightUserChoice = $event" style="min-width:170px;" />
        </div>
    </div>
    <div class="form-group row">
        <label for="weight" class="col-lg-3 field-label">{{ $t('fields.f-weight') }} (Kg)</label>
        <div class="col-lg-3">
            <custom-select-val-label :options="weightChoices" :selectid="weightUserChoice.toString()" @input="weightUserChoice = $event" style="min-width:170px;" />
        </div>
    </div>
    <div class="form-group row">
        <label for="hair" class="col-lg-3 field-label">{{ $t('Cheveux') }}</label>
        <div class="col-lg-9">
            <custom-select-val-label :options="hairColorChoices" :selectid="hairColorUserChoice.toString()" @input="hairColorUserChoice = $event" />
            <custom-select-val-label :options="hairLengthChoices" :selectid="hairLengthUserChoice.toString()" @input="hairLengthUserChoice = $event" />
            <custom-select-val-label :options="hairTypeChoices" :selectid="hairTypeUserChoice.toString()" @input="hairTypeUserChoice = $event" />
        </div>
    </div>
    <div class="form-group row">
        <label for="eyes" class="col-lg-3 field-label">{{ $t('fields.f-eyes') }}</label>
        <div class="col-lg-9">
            <custom-select-val-label :options="eyesChoices" :selectid="eyesUserChoice.toString()" @input="eyesUserChoice = $event" />
        </div>
    </div>
    <div class="form-group row">
        <label for="silhouette" class="col-lg-3 field-label">{{ $t('fields.f-silhouette') }}</label>
        <div class="col-lg-9">
            <custom-select-val-label :options="silhouetteChoices" :selectid="silhouetteUserChoice.toString()" @input="silhouetteUserChoice = $event" />
        </div>
    </div>
    <div class="form-group row">
        <label for="sign" class="col-lg-3 field-label">{{ $t('fields.f-sign') }}</label>
        <div class="col-lg-9">
            <multi-select name="sign" :items="signChoices" :selected="signUserChoices"></multi-select>
        </div>
    </div>
    <div class="form-group row">
        <label for="style" class="col-lg-3 field-label">{{ $t('fields.f-style') }}</label>
        <div class="col-lg-9">
            <multi-select name="style" :items="styleChoices" :selected="styleUserChoices"></multi-select>
        </div>
    </div>
    <div class="form-group row">
        <label for="origin" class="col-lg-3 field-label">{{ $t('fields.f-origin') }}</label>
        <div class="col-lg-9">
            <multi-select name="origin" :items="originChoices" :selected="originUserChoices"></multi-select>
        </div>
    </div>
    <div class="form-group row">
        <label for="sport" class="col-lg-3 field-label">{{ $t('fields.f-sport') }}</label>
        <div class="col-lg-9">
            <multi-select name="sport" :items="sportChoices" :selected="sportUserChoices"></multi-select>
        </div>
    </div>
</template>

<script>
    import MultiSelect from "../fields/MultiSelect.vue";
    import CustomSelectValLabel from "@/components/shared/fields/CustomSelectValLabel.vue";

    export default {
        name: "fields-physical",
        components: {
            MultiSelect,
            CustomSelectValLabel
        },
        props: {
            fields: {
                type: Object,
                required: true
            },
            userChoices: {
                type: Object,
                required: true
            }
        },
        methods: {
            getUserChoice(slug) {
                let filtered = this.userChoices.filter(c => c.field_Slug === slug)
                if (filtered.length) {
                    let field = filtered[0]
                    if (field.choices.length) {
                        return field.choices[0]
                    }
                }
                return '';
            },
            getUserChoices(slug) {
                let filtered = this.userChoices.filter(c => c.field_Slug === slug)
                if (filtered.length) {
                    return filtered[0].choices
                }
                return []
            }
        },
        computed: {
            heightChoices() {
                const heights = this.fields.filter(c => c.slug === 'height')[0].choices
                let choices = [{ value: '0', label: this.$t('Faites votre choix') }]
                for (var i = 0; i < heights.length; i++) {
                    choices.push({ value: heights[i].id, label: heights[i].slug + ' cm' });
                }

                return choices
            },
            heightUserChoice: {
                get() { return this.getUserChoice('height') },
                set(choice) { this.userChoices.filter(c => c.field_Slug === 'height')[0].choices[0] = choice }
            },

            weightChoices() {
                const weights = this.fields.filter(c => c.slug === 'weight')[0].choices
                let choices = [{ value: '0', label: this.$t('Faites votre choix') }]
                for (var i = 0; i < weights.length; i++) {
                    choices.push({ value: weights[i].id, label: weights[i].slug + ' kg' });
                }

                return choices
            },
            weightUserChoice: {
                get() { return this.getUserChoice('weight') },
                set(choice) { this.userChoices.filter(c => c.field_Slug === 'weight')[0].choices[0] = choice }
            },

            silhouetteChoices() {
                const silhouettes = this.fields.filter(c => c.slug === 'silhouette')[0].choices
                let choices = [{ value: '0', label: this.$t('Faites votre choix') }]
                for (var i = 0; i < silhouettes.length; i++) {
                    choices.push({ value: silhouettes[i].id, label: this.$t('fields.silhouette.' + silhouettes[i].slug) });
                }

                return choices
            },
            silhouetteUserChoice: {
                get() { return this.getUserChoice('silhouette') },
                set(choice) { this.userChoices.filter(c => c.field_Slug === 'silhouette')[0].choices[0] = choice }
            },

            eyesChoices() {
                const eyes = this.fields.filter(c => c.slug === 'eyes')[0].choices
                let choices = [{ value: '0', label: this.$t('Faites votre choix') }]
                for (var i = 0; i < eyes.length; i++) {
                    choices.push({ value: eyes[i].id, label: this.$t('fields.eyes.' + eyes[i].slug) });
                }

                return choices
            },
            eyesUserChoice: {
                get() { return this.getUserChoice('eyes') },
                set(choice) { this.userChoices.filter(c => c.field_Slug === 'eyes')[0].choices[0] = choice }
            },

            hairColorChoices() {
                const hairColors = this.fields.filter(c => c.slug === 'hair-color')[0].choices
                let choices = [{ value: '0', label: this.$t('Faites votre choix') }]
                for (var i = 0; i < hairColors.length; i++) {
                    choices.push({ value: hairColors[i].id, label: this.$t('fields.hair-color.' + hairColors[i].slug) });
                }

                return choices
            },
            hairColorUserChoice: {
                get() { return this.getUserChoice('hair-color') },
                set(choice) { this.userChoices.filter(c => c.field_Slug === 'hair-color')[0].choices[0] = choice }
            },
            hairLengthChoices() {
                const hairLengths = this.fields.filter(c => c.slug === 'hair-length')[0].choices
                let choices = [{ value: '0', label: this.$t('Faites votre choix') }]
                for (var i = 0; i < hairLengths.length; i++) {
                    choices.push({ value: hairLengths[i].id, label: this.$t('fields.hair-length.' + hairLengths[i].slug) });
                }

                return choices
            },
            hairLengthUserChoice: {
                get() { return this.getUserChoice('hair-length') },
                set(choice) { this.userChoices.filter(c => c.field_Slug === 'hair-length')[0].choices[0] = choice }
            },
            hairTypeChoices() {
                const hairTypes = this.fields.filter(c => c.slug === 'hair-type')[0].choices
                let choices = [{ value: '0', label: this.$t('Faites votre choix') }]
                for (var i = 0; i < hairTypes.length; i++) {
                    choices.push({ value: hairTypes[i].id, label: this.$t('fields.hair-type.' + hairTypes[i].slug) });
                }

                return choices
            },
            hairTypeUserChoice: {
                get() { return this.getUserChoice('hair-type') },
                set(choice) { this.userChoices.filter(c => c.field_Slug === 'hair-type')[0].choices[0] = choice }
            },

            signChoices() {
                return this.fields.filter(c => c.slug === 'sign')[0].choices
            },
            signUserChoices: {
                get() { return this.getUserChoices('sign') },
                set(choices) { this.userChoices.filter(c => c.field_Slug === 'sign')[0].choices = choices }
            },

            styleChoices() {
                return this.fields.filter(c => c.slug === 'style')[0].choices
            },
            styleUserChoices: {
                get() { return this.getUserChoices('style') },
                set(choices) { this.userChoices.filter(c => c.field_Slug === 'style')[0].choices = choices }
            },

            originChoices() {
                return this.fields.filter(c => c.slug === 'origin')[0].choices
            },
            originUserChoices: {
                get() { return this.getUserChoices('origin') },
                set(choices) { this.userChoices.filter(c => c.field_Slug === 'origin')[0].choices = choices }
            },

            sportChoices() {
                return this.fields.filter(c => c.slug === 'sport')[0].choices
            },
            sportUserChoices: {
                get() { return this.getUserChoices('sport') },
                set(choices) { this.userChoices.filter(c => c.field_Slug === 'sport')[0].choices = choices }
            }
        },
    }
</script>