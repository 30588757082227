<template>
    <div class="card subscriber-item" v-if="!isHidden">
        <!--class="card subscriber-item"-->
        <a :class="{ 'card-header header': true, 'collapsed': !open, 'open': open }" @click="open = !open">
            <div class="subscriber-item-header">
                <div class="subscriber-item-date">{{ profile.postedAt }}</div>
                <div class="subscriber-item-name"><strong>{{ profile.lastName }}</strong> {{ profile.firstName }}</div>
                <div class="subscriber-item-contact-pref">{{ profile.contact }}</div>
                <div class="subscriber-item-status">{{ status }}</div>
                <div class="subscriber-item-edit">
                    <i class="fas fa-chevron-down float-right"></i>
                </div>
            </div>
        </a>
        <transition @enter="onEnter" @leave="onLeave">
            <div class="card-body" v-if="open">
                <div class="subscriber-item-main d-flex ">
                    <div class="subscriber-item-main-column">
                        <div class="subscriber-item-gender"><span class="subscriber-item-label">Genre:</span> {{ profile.genderType }}</div>
                        <div class="subscriber-item-birthday"><span class="subscriber-item-label">Date de naissance:</span> {{ profile.birthDateString }}</div>
                        <div class="subscriber-item-postalcode"><span class="subscriber-item-label">Code postal:</span> {{ profile.zipCode }}</div>
                        <div class="subscriber-item-contactpref"><span class="subscriber-item-label">A contacter par:</span> {{ profile.contactType }}</div>
                        <div class="subscriber-item-tel"><span class="subscriber-item-label">Téléphone:</span> {{ profile.phone }}</div>
                        <div class="subscriber-item-email"><span class="subscriber-item-label">Email:</span> <a :href="'mailto:' + profile.email">{{ profile.email }}</a></div>
                        <div class="subscriber-item-tel"><span class="subscriber-item-label">Adresse IP:</span> {{ profile.ipAddress }}</div>
                    </div>
                    <div class="subscriber-item-main-column">
                        <label for="adminComment" class="field-label">Commentaires</label>
                        <textarea class="form-control" rows="2" v-model="comment" @input="saveComment"></textarea>
                    </div>
                    <div class="subscriber-item-main-column">
                        <label for="selectSubscriberItemStatus1" class="field-label">Statut de la demande</label>
                        <select class="form-control" v-model="status" @change="saveStatus">
                            <option value="Preregistered">Préinscrit</option>
                            <option value="Waiting">En attente</option>
                            <option value="FakeProfile">Faux profil</option>
                        </select>
                    </div>
                    <div class="subscriber-item-main-fullwidth">
                        <a href="#" data-toggle="modal" data-target="#deleteSubecriberItem" class="btn btn-secondary" @click="deactivate">Désactiver ce profil</a>
                        <a href="#" class="btn btn-primary" @click.prevent="convertToProfile">Convertir en profil</a>
                    </div>
                </div>

            </div>
        </transition>
    </div>
</template>

<script>
    import * as Velocity from "velocity-animate";
    import _ from "lodash";
    import axios from "axios";

    export default {
        name: "preregistered-row",
        props: {
            profile: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                isHidden: false,
                open: false,
                status: '',
                comment: ''
            };
        },
        mounted() {
            this.status = this.profile.status;
            this.comment = this.profile.adminComment;
        },
        methods: {
            // Slide
            onEnter(el, done) {
                Velocity(el, "slideDown", {
                    duration: 200,
                    easing: "ease-in-out",
                    complete: done
                });
            },
            onLeave(el, done) {
                Velocity(el, "slideUp", {
                    duration: 250,
                    easing: "ease-in-out",
                    complete: done
                });
            },

            // sauvegarde automatique du commentaire
            saveComment: _.debounce(function () {
                let adminComment = new Object();
                adminComment.user_id = this.profile.id
                adminComment.comment = this.comment
                axios.post("/api/adminprofiles/save-admin-comment", adminComment)
            }, 2000),
            // sauvegarde automatique du statut
            saveStatus: _.debounce(function () {
                let userStatus = new Object();
                userStatus.user_id = this.profile.id
                userStatus.status = this.status
                axios.post("/api/adminprofiles/save-status", userStatus)
            }, 500),
            // suppression d'une demande
            deactivate() {
                if (confirm('Voulez-vous vraiment désactiver ce profil?')) {
                    let userStatus = new Object();
                    userStatus.user_id = this.profile.id
                    userStatus.status = "Deactivated"
                    axios.post("/api/adminprofiles/save-status", userStatus)
                    this.isHidden = true
                }

            },
            // conversion en profil
            convertToProfile() {
                let userStatus = new Object();
                userStatus.user_id = this.profile.id
                userStatus.status = "Registered"
                /* eslint-disable */
                axios.post("/api/adminprofiles/save-status", userStatus).then(
                    response => {
                        this.$router.push(`/${this.$i18n.locale}/admin/profile-edit/${this.profile.slug}`)
                    }
                )
                /* eslint-enable */

            },
            beforeRouteEnter(to, from, next) {
                axios.get("/api/profiles/likes")
                    .then(response => {
                        next(vm => vm.setData(response.data));
                    })
            },
        }
    };
</script>

<style lang="scss" scoped>
    .header {
        font-weight: bold;
        cursor: pointer;
        .fa-chevron-down {
            position: relative;
            top: 5px;
            transition: all 0.2s ease-in-out;
        }

        &.open {
            .fa-chevron-down {
            transform: rotate(180deg);
            }
        }
    }
</style>