<template>
    <header v-if="!isBackoffice">
        <div class="bg-darkred" id="navbarHeader">
            <div class="container">
                <div class="row">
                    <!-- Language -->
                    <div class="col align-self-end d-flex flex-row-reverse">
                        <ul class="list-unstyled d-flex align-items-center language-selector">
                            <li v-for="(lang, i) in langs" :key="`lang-${i}`"><a :class="{'small':1,'showpointer':1,'selected':lang==locale}" @click="changeLocale(lang)">{{lang.toUpperCase()}}</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <nav class="navbar navbar-expand-lg navbar-dark authentificated" v-if="isAuthenticated">
            <div class="container d-flex justify-content-between">
                <a href="#" class="navbar-brand d-flex align-items-center">
                    <div class="d-inline-block align-top" alt="" loading="lazy" id="brand-logo">
                        <router-link :to="{ name: 'home-connected', params: { locale } }" v-show="!isRegistered">
                            <img src="../../../public/images/logo-bkg.svg" alt="Mea Sensus">
                        </router-link>
                        <!-- logo without link for incomplete subscriber -->
                        <img src="../../../public/images/logo-bkg.svg" alt="Mea Sensus" v-show="isRegistered">
                    </div>
                </a>
                <button @click="showMenu=!showMenu" class="navbar-toggler" type="button" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>

                <!-- Bannière de navigation: Tableau de bord utilisateur connecté -->
                <div :class="{'navbar-collapse':true, 'collapse': !showMenu}" id="navbarSupportedContent">
                    <ul class="navbar-nav">
                        <li :class="{'nav-item':true, active:activeMenu === 'search'}" @click="changeMenu('search')" v-show="!isRegistered">
                            <router-link :to="{ name: 'search', params: { locale } }" class="nav-link">
                                <div class="dashboard-item-picto picto-search"><img src="../../../public/images/picto-search.svg" alt="Recherche" /></div>
                                <div class="dashboard-item-label">{{ $t('Recherche') }}</div>
                            </router-link>
                        </li>
                        <li :class="{'nav-item':true, active:activeMenu === 'online'}" @click="changeMenu('online')" v-show="!isRegistered">
                            <router-link :to="{ name: 'online', params: { locale } }" class="nav-link">
                                <div class="dashboard-item-picto picto-search"><img src="../../../public/images/picto-online.svg" alt="Online" /></div>
                                <div class="dashboard-item-label">{{ $t('On line') }}</div>
                            </router-link>
                        </li>
                        <li :class="{'nav-item':true, active:activeMenu === 'events'}" @click="changeMenu('events')" v-show="!isRegistered">
                            <router-link :to="{ name: 'events', params: { locale } }" class="nav-link">
                                <div class="dashboard-item-badge-events">
                                    <span class="badge badge-pill badge-dashboard" v-if="unseenEvents">{{unseenEvents}}</span>
                                </div>
                                <div class="dashboard-item-picto picto-events"><img src="../../../public/images/picto-events.svg" alt="Events" /></div>
                                <div class="dashboard-item-label">{{ $t('Evènements') }}</div>
                            </router-link>
                        </li>
                    </ul>
                    <div class="d-flex" id="navbar-dashboard">
                        <div class="navbar-dashboard-item" v-show="showVisits && !isRegistered" @click="changeMenu('visites')">
                            <router-link :to="{ name: 'visits', params: { locale } }">
                                <div class="dashboard-item-badge"><span class="badge badge-pill badge-dashboard" v-if="visits">{{visits}}</span></div>
                                <div class="dashboard-item-picto picto-visites"><img src="../../../public/images/picto-visites.svg" alt="Visites" /></div>
                                <div class="dashboard-item-label">{{ $t('Visites') }}</div>
                            </router-link>
                        </div>
                        <div class="navbar-dashboard-item" v-show="showLikes && !isRegistered" @click="changeMenu('likes')">
                            <router-link :to="{ name: 'likes', params: { locale } }">
                                <div class="dashboard-item-badge"><span class="badge badge-pill badge-dashboard" v-if="likes">{{likes}}</span></div>
                                <div class="dashboard-item-picto picto-likes"><img src="../../../public/images/picto-likes.svg" alt="Likes" /></div>
                                <div class="dashboard-item-label">{{ $t('Likes') }}</div>
                            </router-link>
                        </div>
                        <div class="navbar-dashboard-item" v-show="showMessages && !isRegistered" @click="changeMenu('messages')">
                            <router-link :to="{ name: 'chat', params: { locale } }">
                                <div class="dashboard-item-badge"><span class="badge badge-pill badge-dashboard" v-if="messages">{{messages}}</span></div>
                                <div class="dashboard-item-picto picto-messages"><img src="../../../public/images/picto-messages.svg" alt="Messages" /></div>
                                <div class="dashboard-item-label">{{ $t('Messages') }}</div>
                            </router-link>
                        </div>
                        <div class="navbar-dashboard-item" id="navbar-profil">
                            <div class="dashboard-item-picto picto-profil"><img src="../../../public/images/picto-profil.svg" alt="Profil" /></div>
                            <div class="dashboard-item-label"><a href="#" class="stretched-link showpointer" @click.prevent="">{{ $t('Profil') }}</a></div>
                            <div id="navbar-dashboard-profile-menu" class="hide">
                                <ul>
                                    <li v-show="!isRegistered" @click="changeMenu('profile')"><router-link :to="{ name: 'profile-edit', params: { locale } }">{{ $t('Editer mon profil') }}</router-link></li>
                                    <li @click="changeMenu('logout')"><a v-on:click.prevent="logout" class="showpointer">{{ $t('Se déconnecter') }}</a></li>
                                </ul>
                            </div>
                        </div>
                        <!-- Admin menu link -->
                        <div class="navbar-dashboard-item" v-if="isAdmin" @click="setBackoffice">
                            <router-link :to="{ name: 'admin-preregistered', params: { locale } }">
                                <div class="dashboard-item-picto picto-admin"><img src="../../../public/images/picto-admin.svg" alt="Accès administrateur" /></div>
                                <div class="dashboard-item-label">Accès</div>
                            </router-link>
                        </div>
                    </div>
                </div>
                <!-- END Bannière de navigation: Tableau de bord utilisateur connecté -->
            </div>
        </nav>
        <nav class="navbar navbar-expand-lg navbar-dark" v-if="!isAuthenticated">
            <div class="container d-flex justify-content-between">
                <a href="#" class="navbar-brand d-flex align-items-center">
                    <div class="d-inline-block align-top public-website" alt="" loading="lazy" id="brand-logo">
                        <router-link :to="{ name: 'home', params: { locale } }">
                            <img src="../../../public/images/logo-bkg.svg" alt="Mea Sensus">
                        </router-link>
                    </div>
                </a>
                <button class="navbar-toggler" type="button" @click="showMenu=!showMenu" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <!-- Bannière de navigation: Boutons de connection - utilisateur NON connecté -->
                <div :class="{'navbar-collapse':true, 'collapse': !showMenu}" id="navbarSupportedContent">
                    <ul class="navbar-nav">
                        <li :class="{'nav-item':true, active:activeMenu === 'home'}" @click="changeMenu('home')">
                            <router-link :to="{ name: 'home', params: { locale } }" class="nav-link">
                                <div class="dashboard-item-picto picto-home"><img src="../../../public/images/picto-home.svg" alt="Home" /></div>
                                <div class="dashboard-item-label">{{ $t('Accueil') }}</div>
                            </router-link>
                        </li>
                    </ul>

                    <div class="d-flex" id="navbar-dashboard">
                        <div :class="{'navbar-dashboard-item':true, active:activeMenu === 'inscrire'}" @click="changeMenu('inscrire')">
                            <router-link :to="{ name: 'register', params: { locale } }">
                                <div class="dashboard-item-picto picto-register"><img src="../../../public/images/picto-login.svg" alt="login" /></div>
                                <div class="dashboard-item-label">{{ $t("S'inscrire") }}</div>
                            </router-link>
                        </div>
                        <div :class="{'navbar-dashboard-item':true, active:activeMenu === 'login'}" @click="changeMenu('login')">
                            <router-link :to="{ name: 'login', params: { locale } }">
                                <div class="dashboard-item-picto picto-admin"><img src="../../../public/images/picto-admin.svg" alt="login" /></div>
                                <div class="dashboard-item-label">{{ $t('Se connecter') }}</div>
                            </router-link>
                        </div>
                    </div>
                    <!-- END Bannière de navigation: Boutons de connection - utilisateur NON connecté -->
                </div>
            </div>
        </nav>
        <div class="bg-darkred" id="navbarMessage" v-if="blockAlert != '' || closeToExpiration">
            <div class="container">
                <p v-if="blockAlert">{{ blockAlert }}</p>
                <p v-if="closeToExpiration">
                    {{ $t("Attention, votre abonnement touche bientôt à sa fin") }} <router-link :to="{ name: 'subscription', params: { locale } }">{{ $t("lien") }}</router-link>
                </p>
            </div>
        </div>

    </header>

    <!-- START Menu Admin -->
    <header v-else>
        <div class="bg-darkred" id="navbarHeader">
            <div class="container">
                <div class="row">
                    <div class="col align-self-start d-flex">
                        <!--<router-link :to="{ name: 'home-connected', params: { locale } }" class="header-link">
                        <span><i class="fas fa-arrow-left" aria-hidden="true"></i></span> Retour vers le site
                    </router-link>-->
                        <a href="#" class="header-link" @click.prevent="backToSite"><span><i class="fas fa-arrow-left" aria-hidden="true"></i></span> Retour vers le site</a>
                    </div>
                </div>
            </div>
        </div>
        <!-- Bannière de navigation: logo + menu de navigation -->
        <nav class="navbar navbar-expand-lg navbar-dark authentificated bg-back-office">
            <div class="container d-flex justify-content-between">
                <!--<div class=""><strong>Administration</strong></div>-->
                <div class="navbar-brand d-flex align-items-center">
                    <div class="d-inline-block align-top" alt="" loading="lazy" id="brand-logo">
                        <img src="../../../public/images/logo-bkg.svg" alt="Mea Sensus">
                    </div>
                </div>
                <button class="navbar-toggler" type="button" @click="showMenu=!showMenu" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div :class="{'navbar-collapse':true, 'collapse': !showMenu}" id="navbarSupportedContent">
                    <ul class="navbar-nav mr-auto">
                        <li :class="{'nav-item':true, active:activeMenu == 'inscriptions'}" @click="changeMenu('inscriptions')"><router-link :to="{ name: 'admin-preregistered', params: { locale } }" class="nav-link">Inscriptions</router-link></li>
                        <li :class="{'nav-item':true, active:activeMenu == 'profils'}" @click="changeMenu('profils')"><router-link :to="{ name: 'admin-profiles', params: { locale } }" class="nav-link">Profils</router-link></li>
                        <li :class="{'nav-item':true, active:activeMenu == 'validation'}" @click.enter="changeMenu('validation')"><router-link :to="{ name: 'admin-validation', params: { locale } }" class="nav-link">Validation</router-link></li>
                        <li :class="{'nav-item':true, active:activeMenu == 'reports'}" @click.enter="changeMenu('reports')"><router-link :to="{ name: 'admin-reports', params: { locale } }" class="nav-link">Signalements</router-link></li>
                        <li :class="{'nav-item':true, active:activeMenu == 'contenus'}" @click.enter="changeMenu('contenus')"><router-link :to="{ name: 'admin-content-places', params: { locale } }" class="nav-link">Contenus</router-link></li>
                    </ul>
                    <!-- Bannière de navigation: Tableau de bord utilisateur connecté -->
                    <div class="d-flex" id="navbar-dashboard">
                        <div class="navbar-dashboard-item back">
                            <div class="nav-link"><a v-on:click.prevent="logout" class="stretched-link showpointer">log-out</a></div>
                        </div>
                    </div>
                    <!-- END Bannière de navigation: Tableau de bord utilisateur connecté -->
                </div>
            </div>
        </nav>
    </header>
    <!-- END Menu Admin -->

</template>

<script>
    import { defineComponent, computed, watch, ref } from 'vue'
    import { useRouter, useRoute } from 'vue-router'
    import { useI18n } from 'vue-i18n'
    import { SUPPORT_LOCALES } from '@/i18n'
    import { useStore } from 'vuex'

    export default defineComponent({
        name: 'Nav',
        setup() {
            const router = useRouter()
            const route = useRoute()
            const { t, locale } = useI18n({ useScope: 'global' })
            const langs = ['fr', 'nl', 'en']
            const currentLocale = ref(locale.value)
            // Auth
            const store = useStore()
            const isAuthenticated = computed(() => store.getters.isAuthenticated && !store.getters.isInRole('Blocked'))
            const isRegistered = computed(() => store.getters.isInRole('Registered') || store.getters.isInRole('IncompleteSubscriber') || store.getters.isInRole('IncompleteGuest'))
            const isAdmin = computed(() => store.getters.isInRole("Admin"))
            const logout = function () {
                store.dispatch("logout").then(() => {
                    console.log('LOGOUT')
                    router.push({
                        name: 'home',
                        params: { locale: currentLocale.value }
                    })
                })
            }
            const closeToExpiration = computed(() => store.getters.closeToExpiration && isAuthenticated.value && router.currentRoute.value.name != 'subscription')

            // from backoffice to website
            const backToSite = function () {
                store.dispatch("switchBackoffice")
                router.push({
                    name: 'home-connected',
                    params: { locale: currentLocale.value }
                })
            }

            // visits - likes - messages
            // -------------------------
            if (isAuthenticated.value && !isRegistered.value) {
                store.dispatch("getInteractionCounts")
            }
            store.dispatch('incrementVisits', 1)
            const unseenEvents = computed(() => store.getters.unseenEvents)
            const visits = computed(() => store.getters.visits)
            const showVisits = computed(() => store.getters.showVisits)
            const likes = computed(() => store.getters.likes)
            const showLikes = computed(() => store.getters.showLikes)
            const messages = computed(() => store.getters.messages)
            const showMessages = computed(() => store.getters.showMessages)
            const blockAlert = computed(function () {
                if (route.query.block != undefined) {
                    return route.query.block + ' ' + t('a bien été bloqué')
                } else {
                    return ''
                }
            })
            const connectionState = computed(() => store.getters.getConnection == null ? 'waiting' : store.getters.getConnection.connectionState)
            watch(connectionState, state => {
                if (state == "Connected") {
                    store.getters.getConnection.on("ReceiveInteraction", function (sender, type) {
                        const page = router.currentRoute.value.name
                        if (type == 1) {
                            if (page != 'visits') {
                                store.dispatch('incrementVisits', 1)
                            }
                        } else if (type == 2) {
                            if (page != 'likes') {
                                store.dispatch('incrementLikes', 1)
                            }
                        }
                        else if (type == 3) {
                            if (page != 'chat') {
                                store.dispatch('incrementMessages', 1)
                            }
                        }
                        else if (type == 4) {
                            if (page != 'events') {
                                console.log("call increment event")
                                store.dispatch('incrementEvents')
                            }
                        }
                    })
                }
            })

            //
            let isBackoffice = computed(() => store.state.isBackoffice && isAdmin.value)
            const setBackoffice = function () {
                store.dispatch("switchBackoffice")
                activeMenu.value = isBackoffice.value ? 'inscriptions' : 'home2'
            }

            let showMenu = ref(false)
            let activeMenu = ref('home')
            if (isAuthenticated.value) {
                activeMenu.value = 'home2'
            }
            if (isBackoffice.value) {
                activeMenu.value = 'inscriptions'
            }
            const changeMenu = function (menu) {
                activeMenu.value = menu
                showMenu.value = false
            }


            // Gestion I18N, locales
            let flag = 0
            const changeLocale = function (locale) {
                currentLocale.value = locale;
                flag = 0
            }
            //const userLanguage = store.getters.language
            //console.log('ma langue est ' + userLanguage)
            //changeLocale(user)
            /**
             * select locale value for language select form
             *
             * If you use the vue-i18n composer `locale` property directly, it will be re-rendering component when this property is changed,
             * before dynamic import was used to asynchronously load and apply locale messages
             * To avoid this, use the anoter locale reactive value.
             */

            // sync to switch locale from router locale path
            watch(router.currentRoute, route => {
                currentLocale.value = route.params.locale
                flag = 1
            })
            /**
             * when change the locale, go to locale route
             *
             * when the changes are detected, load the locale message and set the language via vue-router navigation guard.
             * change the vue-i18n locale too.
             */
            watch(currentLocale, val => {
                if (flag == 0) {
                    router.push({
                        name: router.currentRoute.value.name,
                        params: { locale: val }
                    })
                } else {
                    flag = 0
                }
            })

            return {
                t, locale, currentLocale, supportLocales: SUPPORT_LOCALES, langs, changeLocale,
                isAuthenticated, isAdmin, logout, isBackoffice, isRegistered, setBackoffice, changeMenu, activeMenu,
                unseenEvents, visits, showVisits, likes, showLikes, messages, showMessages, showMenu, backToSite, blockAlert, closeToExpiration
            }
        }
    })
</script>
