<template>
    <main role="main">
        <div class="container">
            <div class="row">
                <div class="col-md-12 text-center mt-5 mb-4">
                    <h1>Utilisateurs signalés</h1>
                </div>
            </div>
        </div>

        <!-- Résultats -->
        <div id="back-search-results" class="col-md-12 mt-5 mb-5">
            <div class="container">
                <div class="row">

                    <!-- USER 1 -->
                    <div class="user-validation-group" v-for="user in reports" :key="user.user_Id">
                        <!-- ID User -->
                        <div class="user-id">
                            <div class="user-id-picture"><img :src="user.pictureCode" class="card-img-top" alt="..."></div>
                            <div class="user-id-body"><router-link :to="{ name: 'admin-profile-edit', params: { locale: $i18n.locale, slug: user.userSlug } }"><h2>{{ user.pseudo }}</h2></router-link></div>
                        </div>

                        <!-- Signalements -->
                        <div class="banandblock">
                            <h4>Signalement(s)</h4>
                            <!-- Liste des signalements -->
                            <div class="banandblock-list">
                                <!-- Liste des signalements - Lables des colonnes -->
                                <div class="banandblock-list-item header">
                                    <div class="list-profile">Signalé par</div>
                                    <div class="list-date">Date</div>
                                    <div class="list-abstract">Motif</div>
                                    <div class="list-action"></div>
                                </div>
                                <!-- Liste des signalements - Entrées de la liste -->
                               <div class="banandblock-list-item" v-for="report in user.reports" :key="report.userName">
                                    <div class="list-profile">
                                        <div class="list-profile-picture"><img :src="report.pictureCode" class="card-img-top"></div>
                                        <div class="list-profile-body"><router-link :to="{ name: 'admin-profile-edit', params: { locale: $i18n.locale, slug: report.userSlug } }">{{ report.pseudo }}</router-link></div>
                                    </div>
                                    <div class="list-date">{{ $filters.date(report.reportDate) }}</div>
                                    <div class="list-abstract">{{ report.reason }}</div>
                                    <div class="list-action"><a href="#" class="btn btn-primary btn-sm" @click.prevent="processReport(user.user_Id, report.interaction_Id)">Signalement traité</a></div>
                               </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <!-- END Résultats -->
    </main>
</template>

<script>
    import axios from "axios";

    export default {
        name: 'reports',
        data() {
            return {
                reports: [],
                processed: []
            }
        },
        methods: {
            setData(reports) {
                this.reports = reports
            },
            processReport(user_id, interaction_id) {
                let ref = this
                axios.get(`/api/reports/process/${interaction_id}`)
                    .then(response => {
                        if (response.data == '') {

                            let user = ref.reports.filter(u => u.user_Id == user_id)
                            console.log('1')
                            if (user !== undefined) {
                                console.log('2')
                                let reports = user[0].reports
                                console.log('3')
                                let toRemove = reports.filter(r => r.interaction_Id == interaction_id)
                                console.log('4')
                                if (toRemove != undefined) {
                                    console.log('5')
                                    let index = reports.indexOf(toRemove)
                                    reports.splice(index, 1)
                                    if (reports.length == 0) {
                                        let userIndex = ref.reports.indexOf(user[0])
                                        ref.reports.splice(userIndex, 1)
                                    }
                                    console.log('6')

                                }
                            }
                        }
                    })
            }
        },
        beforeRouteEnter(to, from, next) {
            axios.get(`/api/reports/reported`)
                .then(response => {
                    next(vm => vm.setData(response.data));
                });
        }
    }
</script>