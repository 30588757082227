<template>
    <div class="form-group row">
        <label for="status" class="col-lg-3 field-label">{{ $t('fields.f-status') }} *</label>
        <div class="col-lg-9">
            <custom-select-val-label :options="statusChoices" :selectid="statusUserChoice.toString()" @input="statusUserChoice = $event" />
        </div>
    </div>
    <div class="form-group row">
        <label class="col-lg-3 field-label">{{ $t('fields.f-children') }} *</label>
        <div class="col-lg-9">
            <radio-inline name="children" :items="childrenChoices" :selected="childrenUserChoice.toString()" @update="childrenUserChoice = $event"></radio-inline>
        </div>
    </div>
    <div class="form-group row">
        <label class="col-lg-3 field-label">{{ $t("fields.f-morechildren") }}</label>
        <div class="col-lg-9">
            <radio-inline name="morechildren" :items="moreChildrenChoices" :selected="moreChildrenUserChoice.toString()" @update="moreChildrenUserChoice = $event"></radio-inline>
        </div>
    </div>
    <div class="form-group row">
        <label for="languages" class="col-lg-3 field-label">{{ $t('fields.f-languages') }}</label>
        <div class="col-lg-9">
            <multi-select name="languages" :items="languagesChoices" :selected="languagesUserChoices"></multi-select>
        </div>
    </div>
    <div class="form-group row">
        <label for="education" class="col-lg-3 field-label">{{ $t('fields.f-education') }}</label>
        <div class="col-lg-9">
            <custom-select-val-label :options="educationChoices" :selectid="educationUserChoice.toString()" @input="educationUserChoice = $event" />
        </div>
    </div>
</template>

<script>
    import MultiSelect from "../fields/MultiSelect.vue";
    import RadioInline from "../fields/RadioInline.vue";
    import CustomSelectValLabel from "@/components/shared/fields/CustomSelectValLabel.vue";

    export default {
        name: "fields-mental",
        components: {
            MultiSelect,
            RadioInline,
            CustomSelectValLabel
        },
        props: {
            fields: {
                type: Object,
                required: true
            },
            userChoices: {
                type: Object,
                required: true
            }
        },
        methods: {
            getUserChoice(slug) {
                let filtered = this.userChoices.filter(c => c.field_Slug === slug)
                if (filtered.length) {
                    let field = filtered[0]
                    if (field.choices.length) {
                        return field.choices[0]
                    }
                }
                return '';
            },
            getUserChoices(slug) {
                let filtered = this.userChoices.filter(c => c.field_Slug === slug)
                if (filtered.length) {
                    return filtered[0].choices
                }
                return []
            }
        },
        computed: {
            statusChoices() {
                const statuses = this.fields.filter(c => c.slug === 'status')[0].choices
                let choices = [{ value: '0', label: this.$t('Faites votre choix') }]
                for (var i = 0; i < statuses.length; i++) {
                    choices.push({ value: statuses[i].id, label: this.$t('fields.status.' + statuses[i].slug) });
                }

                return choices
            },
            statusUserChoice: {
                get() { return this.getUserChoice('status') },
                set(choice) { this.userChoices.filter(c => c.field_Slug === 'status')[0].choices[0] = choice }
            },

            childrenChoices() {
                return this.fields.filter(c => c.slug === 'children')[0].choices
            },
            childrenUserChoice: {
                get() { return this.getUserChoice('children') },
                set(choice) { this.userChoices.filter(c => c.field_Slug === 'children')[0].choices[0] = choice }
            },

            moreChildrenChoices() {
                return this.fields.filter(c => c.slug === 'morechildren')[0].choices
            },
            moreChildrenUserChoice: {
                get() { return this.getUserChoice('morechildren') },
                set(choice) { this.userChoices.filter(c => c.field_Slug === 'morechildren')[0].choices[0] = choice }
            },

            languagesChoices() {
                return this.fields.filter(c => c.slug === 'languages')[0].choices
            },
            languagesUserChoices: {
                get() { return this.getUserChoices('languages') },
                set(choices) { this.userChoices.filter(c => c.field_Slug === 'languages')[0].choices = choices }
            },

            educationChoices() {
                const educations = this.fields.filter(c => c.slug === 'education')[0].choices
                let choices = [{ value: '0', label: this.$t('Faites votre choix') }]
                for (var i = 0; i < educations.length; i++) {
                    choices.push({ value: educations[i].id, label: this.$t('fields.education.' + educations[i].slug) });
                }

                return choices
            },
            educationUserChoice: {
                get() { return this.getUserChoice('education') },
                set(choice) { this.userChoices.filter(c => c.field_Slug === 'education')[0].choices[0] = choice }
            },
        },
    }
</script>