<template>
    <template-a v-if="content.contentTemplate_Slug=='a'" :content="content" :picture="picture"></template-a>
    <template-b v-if="content.contentTemplate_Slug=='b'" :content="content" :picture="picture"></template-b>
    <template-c v-if="content.contentTemplate_Slug=='c'" :content="content"></template-c>
    <template-d v-if="content.contentTemplate_Slug=='d'" :content="content"></template-d>
    <template-e v-if="content.contentTemplate_Slug=='e'" :content="content" :picture="picture"></template-e>
</template>

<script>
    import TemplateA from "@/components/shared/content/TemplateA.vue"
    import TemplateB from "@/components/shared/content/TemplateB.vue"
    import TemplateC from "@/components/shared/content/TemplateC.vue"
    import TemplateD from "@/components/shared/content/TemplateD.vue"
    import TemplateE from "@/components/shared/content/TemplateE.vue"
    import axios from "axios";

    export default {
        name: "content-Display",
        components: {
            TemplateA,
            TemplateB,
            TemplateC,
            TemplateD,
            TemplateE,
        },
        data() {
            return {
                picture: ''
            }
        },
        props: {
            content: Object
        },
        mounted() {
            this.getPicture()
        },
        methods: {
            getPicture() {
                let pict = this.content.picture
                if (pict != undefined && pict != null && pict != '') {
                    axios.get(`/api/contents/picture/${pict}`)
                        .then(response => {
                            this.picture = response.data
                        });
                }
            },
        }
    }
</script>