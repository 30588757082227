<template>
    <div class="form-row" id="date-field-vue">
        <div class="col-sm">
            <custom-select-val-label class="test" :options="days" :selectid="date.toString()" @input="editValue('date', $event)" />
        </div>
        <div class="col-sm">
            <custom-select-val-label class="test" :options="months" :selectid="month.toString()" @input="editValue('months', $event)" />
        </div>
        <div class="col-sm">
            <custom-select-val-label class="test" :options="years" :selectid="year.toString()" @input="editValue('year', $event)" />
        </div>
    </div>
</template>

<script>
    import { ref, onMounted, computed } from 'vue'
    import moment from 'moment'
    import CustomSelectValLabel from "@/components/shared/fields/CustomSelectValLabel.vue";
    import { useI18n } from 'vue-i18n'

    export default {
        name: "date-field",
        components: {
            CustomSelectValLabel,
        },
        props: {
            minYearFromToday: Number,
            maxYearFromToday: Number,
            selectedDate: String
        },
        setup(props, context) {

            const { t } = useI18n()
            const months = computed(() => [
                { value: "-1", label: t('Mois') },
                { value: "0", label: t('mois.janvier') },
                { value: "1", label: t('mois.février') },
                { value: "2", label: t('mois.mars') },
                { value: "3", label: t('mois.avril') },
                { value: "4", label: t('mois.mai') },
                { value: "5", label: t('mois.juin') },
                { value: "6", label: t('mois.juillet') },
                { value: "7", label: t('mois.août') },
                { value: "8", label: t('mois.septembre') },
                { value: "9", label: t('mois.octobre') },
                { value: "10", label: t('mois.novembre') },
                { value: "11", label: t('mois.décembre') },
            ])


            const startingYear = new Date().getFullYear() + props.minYearFromToday

            let yearList = []
            let y = new Date().getFullYear() + props.minYearFromToday
            let endDate = new Date().getFullYear() + props.maxYearFromToday
            for (y; y < endDate; y++) {
                yearList.push({ value: y.toString(), label: y.toString() })
            }
            const years = computed(() => [{ value: '0', label: t('Année') }].concat(yearList))

            let dateValue = moment() // defaults to current date
            const daysInMonth = ref(dateValue.daysInMonth())

            let days = ref([])
            const setDays = function () {
                let dayList = []
                let d = 1
                for (d; d < daysInMonth.value +1; d++) {
                    dayList.push({ value: d, label: d })
                }
                days.value = [{ value: '0', label: t('Jour') }].concat(dayList)
            }
            setDays()



            const editValue = (unit, evt) => {
                dateValue.set(unit, evt)
                daysInMonth.value = dateValue.daysInMonth()
                updateElements()
                context.emit('update', dateValue.format("YYYY-MM-DD"))
                if (unit != 'date') {
                    setDays()
                }
            }

            const month = ref(-1)
            const date = ref(0)
            const year = ref(0)

            const updateElements = () => {
                // month.value gives us the <select>
                month.value = dateValue.month() == 0 ? -1 : dateValue.month()
                date.value = dateValue.date()
                year.value = dateValue.format('YYYY')
            }

            onMounted(() => {
                if (props.selectedDate != '' && props.selectedDate != null) {
                    if (typeof props.selectedDate === 'object') {
                        dateValue = props.selectedDate
                    } else {
                        dateValue = moment(props.selectedDate)
                    }
                    updateElements()
                }
            })

            return {
                months,
                month,
                date,
                year,
                years,
                startingYear,
                days,
                editValue
            }
        }
    }
</script>

<style scoped>
    #date-field-vue.form-row {
        justify-content: space-between;
    }

    #date-field-vue .col-sm-auto {
        width: auto;
    }

    #date-field-vue .col-sm select {
        width:100%;
    }
</style>