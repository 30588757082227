import 'bootstrap/dist/css/bootstrap.css'
import { createApp } from 'vue'
import App from './App.vue'
import { setupRouter } from './router'
import { setupI18n } from './i18n'
import fr from './locales/fr.json'
import { store } from './store'


store.subscribe((mutation, state) => {
    console.log('* store subscribe')
    localStorage.setItem("store", JSON.stringify(state));
});
import "./helpers/interceptors";

const i18n = setupI18n({
    globalInjection: true,
    legacy: false,
    locale: 'fr',
    fallbackLocale: 'fr',
    messages: {
        fr
    }
})


const router = setupRouter(i18n)

// filters
import { date } from "./filters";
const app = createApp(App)
app.config.globalProperties.$filters = {
    date
}

// click outside modal windows
const clickOutside = {
    beforeMount: (el, binding) => {
        el.clickOutsideEvent = event => {
            // here I check that click was outside the el and his children
            if (!(el == event.target || el.contains(event.target))) {
                // and if it did, call method provided in attribute value
                binding.value(event);
            }
        };
        document.addEventListener("click", el.clickOutsideEvent);
    },
    unmounted: el => {
        document.removeEventListener("click", el.clickOutsideEvent);
    },
};
app.directive("click-outside", clickOutside)


app.use(i18n)
app.use(store)
app.use(router)
app.mount('#app')

// initialiser signalr
if (store.state.auth != null) {
    store.dispatch("setConnection")
}

