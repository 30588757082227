<template>
    <div class="py-5 principe-C">
        <div class="container" v-if="content">
            <div class="row">
                <div class="col-md-12 text-center mb-5">
                    <h1>{{ content.title }}</h1>
                    <h2 v-if="content.subTitle">{{ content.subTitle }}</h2>
                    <p class="lead" v-if="content.headline" v-html="content.headline"></p>
                </div>
            </div>
            <div class="row" v-if="content.subContentDisplays">
                <div class="principe-C principe-3-blockcol d-flex">
                    <!-- Colonne -->
                    <template-f :colClass="colClass" :content="subContent" v-for="subContent in content.subContentDisplays" :key="subContent.id"></template-f>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import TemplateF from './TemplateF.vue'

    export default {
        name: "template-c",
        components: {
            TemplateF
        },
        props: {
            content: null,
        },
        computed: {
            colClass() {
                if (this.content != undefined && this.content != null) {
                    if (this.content.subContentDisplays != null) {
                        if (this.content.subContentDisplays.length > 0) {
                            let width = 12 / this.content.subContentDisplays.length
                            return `mb-${width} col-md-${width}`
                        }
                    }

                }
                return ''
            }
        },
        //methods: {
        //    goToPage() {
        //        let action = 'page'
        //        if (this.content.contentPlace_Slug == 'auth-home' || this.content.contentPlace_Slug == 'events') {
        //            action = 'info'
        //        }
        //        console.log(`/${this.$i18n.locale}/${action}/${this.content.slug}`)
        //        this.$router.push(`/${this.$i18n.locale}/${action}/${this.content.slug}`);
        //    }
        //}
    }
</script>