<template>
    <transition name="fade">
        <div class="modal-inter" id="report" tabindex="-1" role="dialog" aria-hidden="true" v-if="locShow">
            <div class="modal-dialog" role="document" v-click-outside="clickOutsideBlock">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">{{ $t("Signaler un utilisateur") }}</h5>
                        <button type="button" class="close" @click="locShow = false" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <p>{{ $t("Motif du signalement") }}</p>
                        <textarea class="form-control" rows="4" v-model="reason"></textarea>
                        <div class="alert alert-danger" role="alert" v-if="showError">
                            {{ $t("Veuillez encoder le motif du signalement") }}.
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="locShow = false">{{ $t("Annuler") }}</button>
                        <button type="button" class="btn btn-primary" @click.prevent="report">{{ $t("Envoyer le signalement") }}</button>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    //import axios from "axios";

    export default {
        name: "report-modal",
        props: {
            show: {
                type: Boolean,
                required: true
            },
        },
        data() {
            return {
                //isBlocked: false,
                reason: '',
                showError: false
                //locShow: false,
            }
        },
        mounted() {
            if (!this.locShow) {
                this.showError = false
            }
        },
        computed: {
            locShow: {
                get() { return this.show },
                set(locShow) { this.$emit('update', locShow) }
            },
        },
        methods: {
            clickOutsideBlock(e) {
                console.log('outside')
                if (e.target.id != 'report_button') {
                    this.locShow = false
                    this.showError = false
                }
            },
            report() {
                if (this.reason.trim() == '') {
                    this.showError = true
                } else {
                    this.showError = false
                    this.$emit('report', this.reason.trim())
                    this.locShow = false
                }
            },
        }
    };
</script>

<style>
    .modal-inter {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.4);
        z-index: 15000;
    }

    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }

    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }
    .modal-content {background-color: rgb(255,151,67);  color: rgb(168,1,35);}
    .modal-header {border-bottom: 1px solid rgba(168,1,35,0.5); background-color:rgb(168,1,35); color:#fff;}
    .modal-header .close {color: #fff;}
    .modal-footer {border-top: 1px solid rgba(168,1,35,0.5);}
    .btn-primary {color: #fff; background-color: rgb(168,1,35); border-color: rgb(168,1,35);}
    .btn-primary:hover {background-color: rgb(143, 11, 38); border-color: rgb(168,1,35);}
</style>