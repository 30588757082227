<template>
    <main role="main">
        <div class="container">

            <div class="row">
                <div class="col-md-12 mt-2 mb-3">
                    <a href="javascript:history.back()" class="back-link"><span><i class="fas fa-arrow-left" aria-hidden="true"></i></span>{{ $t('Retour') }}</a>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 text-center mb-4">
                    <h1>{{ data.placeName }}</h1>
                    <h2>Liste des blocs de contenus associés à cette page</h2>
                </div>
            </div>

            <!-- Liste des Blocks -->
            <div class="mt-5 mb-5">
                <div class="row drop-zone" v-if="data.placeContents && data.placeContents.length">

                    <content-list :contents="contentList"></content-list>
                    
                </div>
                <div class="row" v-else>
                    <h3>Il n'y a pas encore de contenu encodé pour la destination "{{ data.placeName }}"</h3>
                </div>
                <div class="row">
                    <div class="col-md-12 text-right mt-4 mb-5">
                        <div class="dropdown" @focusout="createFocusOut">
                            <button class="btn btn-primary dropdown-toggle" type="button"  aria-haspopup="true" aria-expanded="false" @click="showCreateMenu=!showCreateMenu">Ajouter un bloc</button>
                            <div class="dropdown-menu" v-show="showCreateMenu">
                                <a class="dropdown-item showpointer" @click.prevent="createContent(template.id)" v-for="template in data.templates" :key="template.id">{{ template.name }}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- END Liste des Blocks -->

        </div>
    </main>
</template>

<script>
    import ContentList from "../../components/admin/content/ContentList.vue"
    import axios from "axios";

    export default {
        name: "contents",
        components: {
            ContentList
        },
        data() {
            return {
                data: Object,
                showCreateMenu: false
            }
        },
        computed: {
            contentList() {
                return this.data.placeContents.slice().sort(function (a, b) {
                    return a.position - b.position;
                });
            },
        },
        methods: {
            setData(data) {
                this.data = data;
            },
            createContent(template_id) {
                this.$router.push(`/${this.$i18n.locale}/admin/content-create?t=${template_id}&p=${this.data.place_Id}`);
            },
            createFocusOut() {
                setTimeout(this.closeMenu, 200)
            },
            closeMenu() {
                this.showCreateMenu = false
            }
        },
        // Chargement initial
        beforeRouteEnter(to, from, next) {
            axios.get(`/api/admincontents/place-contents/${to.params.slug}`)
                .then(response => {
                    next(vm => vm.setData(response.data));
                });
        }
    }
</script>

<style scoped>
    .dropdown-menu {
        right: 0;
        left: auto;
        display: block;
    }
</style>