<template>
    <div class="flex-shrink-0 account-form-container">

        <!-- Formulaire d'identification -->
        <form @submit.prevent="resetPassword" class="form-signin">
            <h1 class="h3 mb-3 font-weight-normal">{{ $t("Réinitialiser mon mot de passe") }}</h1>
            <div v-if="!isDone">
                <div class="form-group">
                    <small id="PasswordHelpBlock" class="form-text text-muted">{{ $t("fields.password-requ") }}</small>
                    <br />&nbsp;
                    <label for="inputPassword" class="sr-only">{{ $t("fields.password") }}</label>
                    <input type="password" v-model.trim="password" id="Password" autocomplete="new-password" class="form-control" v-bind:placeholder="$t('fields.password')" required @input="checkPassword">
                    <div v-if="pwError1" class="err-msg">
                        {{ $t("fields.password-format") }}
                    </div>
                </div>
                <div class="form-group">
                    <label for="inputPassword" class="sr-only">{{ $t("fields.password-confirm") }}</label>
                    <input type="password" id="passwordConfirm" autocomplete="new-password" class="form-control" v-bind:placeholder="$t('fields.password-confirm')" v-model="passwordConfirm" @input="checkPasswordConfirm">
                    <div v-if="pwError2" class="err-msg">
                        {{ $t("fields.password-conf-error") }}
                    </div>
                </div>

                <div class="alert alert-danger" role="alert" v-if="failed">
                    {{ $t("Votre mise à jour a échoué") }}
                </div>

                <button class="btn btn-lg button-link btn-block" type="submit">{{ $t("Enregistrer") }}</button>
            </div>

            <div class="form-group" v-if="isDone">
                <p style="text-align:center;">{{ $t("Votre mot de passe a été modifié avec succès. Authentifiez-vous via ce") }} <router-link :to="{ name: 'login', params: { locale: $i18n.locale } }">{{ $t("lien") }}</router-link></p>
            </div>
            
        </form>
        <!-- END Formulaire d'identification -->
    </div>
</template>

<script>
    import axios from "axios";

    export default {
        name: "reset-password",
        data() {
            return {
                password: '',
                passwordConfirm: '',
                pwError1: false,
                pwError2: false,
                isDone: false,
                failed: false,
            }
        },
        methods: {
            resetPassword() {
                if (!this.pwError1 && !this.pwError2) {
                    /* eslint-disable */
                    axios.post(`/api/account/reset-password`, {
                        password: this.password,
                        email: this.$route.query.email,
                        token: this.$route.query.token
                    })
                        .then(
                            response => {
                                this.isDone = true
                            }
                        )
                        .catch(error => {
                            this.failed = true
                            //console.log(error);
                        });
                    /* eslint-enable */
                }
            },
            checkPassword() {
                // Check format
                var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$&+,:;=?@#|'<>.^*()%!-])(?=.{8,})");
                if (this.password == null || this.password == "" || strongRegex.test(this.password)) {
                    this.pwError1 = false
                } else {
                    this.pwError1 = true
                }
                // Check confirm
                if ((this.password != null && this.password.length) && this.password != this.passwordConfirm) {
                    this.pwError2 = true
                } else {
                    this.pwError2 = false
                }
            },
            checkPasswordConfirm() {
                if (((this.password != null && this.password.length) || this.passwordConfirm.length) && this.password != this.passwordConfirm) {
                    this.pwError2 = true
                } else {
                    this.pwError2 = false
                }
            },
        }
    }
</script>

<style lang="scss" scoped>
    .err-msg {
        width: 100%;
        margin-top: .25rem;
        font-size: 80%;
        color: #dc3545
    }
</style>