<template>
    <div class="flex-shrink-0 account-form-container">

        <!-- Formulaire d'identification -->
        <form @submit.prevent="login" class="form-signin">
            <h1 class="h3 mb-3 font-weight-normal">{{ $t("Se connecter") }}</h1>
            <div class="form-group">
                <label for="Email" class="sr-only">{{ $t("fields.email") }}</label>
                <input type="email" v-model.trim="email" id="Email" class="form-control" v-bind:placeholder="$t('fields.email')" required>
            </div>
            <div class="form-group">
                <label for="Password" class="sr-only">{{ $t("fields.password") }}</label>
                <input type="password" v-model.trim="password" id="Password" class="form-control" v-bind:placeholder="$t('fields.password')" required>
            </div>
            <div class="alert alert-danger small" role="alert" v-if="error !== null" v-html="errorTranslation">
            </div>

            <p class="mb-3 small"><router-link :to="{ name: 'forgot-password', params: { 'locale': $i18n.locale }, query: { 'm': email } }">{{ $t("Mot de passe oublié") }}</router-link></p>
            <button class="btn btn-lg button-link btn-block" type="submit" :disabled="loading">{{ $t("Se connecter") }}</button>
        </form>
        <!-- END Formulaire d'identification -->
    </div>
</template>

<script>
    export default {
        name: "login-form",
        props: {
            registered: {
                type: Boolean,
                required: false
            }
        },
        data() {
            return {
                email: "",
                password: "",
                error: null
            };
        },
        computed: {
            loading() {
                return this.$store.state.loading;
            },
            errorTranslation() {
                if (this.error == 'compte bloqué') {
                    return this.$t(this.error) + ': <a href="mailto:info@measensus.be">info@measensus.be</a>'
                } else {
                    return this.$t(this.error)
                }
            }
        },
        methods: {
            login() {
                const payload = {
                    email: this.email,
                    password: this.password
                };
                this.$store
                    .dispatch("login", payload)
                    // eslint-disable-next-line no-unused-vars
                    .then(response => {
                        this.error = null;
                        //const locale = this.$i18n.locale
                        let locale = this.$store.getters.language
                        if (locale == null) {
                            locale = this.$i18n.locale
                        }
                        if (this.$store.getters.isInRole('Blocked')) {
                            this.error = "compte bloqué" ;
                        } else {
                            // Double authentication
                            if (response.data.needDoubleAuthentication) {
                                // redirect to code check
                                this.$router.push({ name: 'login-code', params: { locale }, query: { email: this.email } })
                            } else {
                                // obtenir les interactions
                                if (!this.$store.getters.isInRole('Registered')) {
                                    this.$store.dispatch("getInteractionCounts")
                                }

                                if (this.$route.query.redirect) {
                                    this.$router.push(this.$route.query.redirect);
                                } else {
                                    if (this.$store.getters.isInRole('IncompleteSubscriber') || this.$store.getters.isInRole('IncompleteGuest')) {
                                        // Incomplete profile
                                        this.$router.push({ name: 'profile-edit', params: { locale }, query: { subscrok: 1 } })
                                    } else {
                                        let url = 'home-connected'
                                        if (this.$store.getters.isInRole('Registered')) {
                                            // subscription page
                                            url = 'subscription'
                                        }
                                        this.$router.push({ name: url, params: { locale } })
                                    }
                                }
                            }
                        }
                    })
                    // eslint-disable-next-line no-unused-vars
                    .catch(error => {
                        this.error = "Identifiants incorrects";
                    });
            }
        }
    };
</script>