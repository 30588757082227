<template>
    <main role="main">

        <div class="pt-5 pb-2">
            <div class="container">
                <div class="row">
                    <div class="col-md-12 text-center mb-2">
                        <h1>Demandes d'inscription</h1>
                    </div>
                </div>
            </div>
        </div>

        <!-- Résultats -->
        <div id="subscribers-list" class="mt-2 mb-5">
            <div class="container" v-if="profiles && profiles.length > 0">

                <div class="row">
                    <div class="subscriber-list-header">
                        <div class="subscriber-item-date">Posté le</div>
                        <div class="subscriber-item-name"><strong>Nom</strong> Prénom</div>
                        <div class="subscriber-item-contact-pref">Contact</div>
                        <div class="subscriber-item-status">Status</div>
                    </div>

                    <div id="accordion">
                        <preregistered-row v-for="profile in profiles" :key="profile.Id" :profile="profile"></preregistered-row>
                    </div>

                </div>
            </div>
            <!-- END Résultats -->
            <div v-else>
                <div class="container">
                    <h4 class="text-center">Il n'y a pas de demande d'inscription en cours.</h4>
                </div>
            </div>
        </div>

    </main>
</template>

<script>
    import PreregisteredRow from "../../components/admin/preregistered/PreregisteredRow.vue"
    import axios from "axios";

    export default {
        name: "preregistered",
        components: {
            PreregisteredRow
        },
        data() {
            return {
                profiles: null
            };
        },
        methods: {
            setData(profiles) {
                this.profiles = profiles;
            }
        },
        beforeRouteEnter(to, from, next) {
            axios.get(`/api/adminprofiles/preregistered`).then(response => {
                next(vm => vm.setData(response.data));
            });
        }
    }
</script>