export const isAuthenticated = state => {
    return (
        state.auth !== null && state.auth != undefined &&
        state.auth.access_token !== null
         //&& new Date(state.auth.access_token_expiration) > new Date()
    );
};
export const isBackoffice = state => {
    return state.isBackoffice
}
export const isInRole = (state, getters) => role => {
    const result = getters.isAuthenticated && state.auth.roles != undefined && state.auth.roles.indexOf(role) > -1;
    return result;
};

export const getStatus = state => {
    return state.status
}

export const getConnection = state => {
    return state.connection
}

export const unseenEvents = state => {
    return state.unseenEventsCount
}
export const visits = state => {
    return state.visitCount
}
export const showVisits = state => {
    return state.showVisits
}
export const likes = state => {
    return state.likeCount
}
export const showLikes = state => {
    return state.showLikes
}
export const messages = state => {
    return state.messageCount
}
export const showMessages = state => {
    return state.showMessages
}
export const closeToExpiration = state => {
    return state.closeToExpiration
}
export const language = state => {
    if (state.auth != undefined) {
        return state.auth.language
    }
    return null
}

export const role = (state, getters) => {
    if (getters.isAuthenticated && state.auth.roles != undefined) {
        return state.auth.roles[0];
    }
}