<template>
    <main role="main">

        <div class="pt-5 pb-2">
            <div class="container">
                <div class="row">
                    <div class="col-md-12 text-center mb-2">
                        <h1>{{ $t("Soirée/Événement") }}</h1>
                    </div>
                </div>
            </div>
        </div>

        <!-- Résultats -->
        <div id="events-results" class="mt-5 mb-5">
            <div class="container">
                <div class="row">
                    <div class="events-grid d-flex" v-if="data">
                        <content-display v-for="content in data" :key="content.id" :content="content"></content-display>
                    </div>
                </div>
            </div>
        </div>
        <!-- END Résultats -->
    </main>
</template>

<script>
    import axios from "axios";
    import ContentDisplay from "../components/shared/content/ContentDisplay.vue"

    export default {
        name: "events",
        components: {
            ContentDisplay
        },
        data() {
            return {
                data: [],
            }
        },
        mounted() {
            this.$store.dispatch('changeUnseenEvents', 0)
        },
        methods: {
            setData(data) {
                this.data = data.value;
            },
        },
        // Chargement initial
        beforeRouteEnter(to, from, next) {
            axios.get(`/api/contents/place-contents/${to.params.locale}/events`)
                .then(response => {
                    next(vm => vm.setData(response.data));
                });
        },
        // Rafraîchir au changement de langue
        beforeRouteUpdate(to, from, next) {
            axios.get(`/api/contents/place-contents/${to.params.locale}/events`)
                .then(response => {
                    this.setData(response.data);
                    next();
                });
        }
    }
</script>