<template>
    <div class="user-validation-group" v-if="(rejectedPictures.length < user.userPictures.length || !isDescrValidated || !user.isPictureValidated) && !allValidated">
        <div class="user-id">
            <div class="user-id-picture"><img :src="user.pictureCode" class="card-img-top"></div>
            <div class="user-id-body"><a href="#" @click.prevent="userEdit"><h2>{{ user.pseudo }}</h2></a></div>
        </div>
        <div class="pictures-validation-form" v-if="(user.userPictures.length>0 && rejectedPictures.length < user.userPictures.length) || (!user.isPictureValidated && !rejectedProfilePictures.includes(user.user_Id))">
            <h4>Photos</h4>
            <div class="profiles-grid d-flex">
                <!-- Profile picture -->
                <div class="grid-item" v-if="!user.isPictureValidated && !rejectedProfilePictures.includes(user.user_Id)">
                    <div class="card">
                        <div class="card-picture"><img :src="user.pictureCode" class="card-img-top"></div>
                        <div class="card-body">
                            <a href="#" class="btn btn-danger btn-sm" @click.prevent="rejectProfilePicture(user.user_Id)">Rejeter</a>
                        </div>
                    </div>
                </div>
                <!-- User pictures -->
                <div class="grid-item" v-for="(picture, index) in user.userPictures" :key="index">
                    <div class="card" v-if="!rejectedPictures.includes(picture.id)">
                        <div class="card-picture"><img :src="picture.pictureCode" class="card-img-top"></div>
                        <div class="card-body">
                            <a href="#" class="btn btn-danger btn-sm" @click.prevent="reject(picture.id)">Rejeter</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="text-validation-form" v-if="user.description && !isDescrValidated">
            <h4>Textes</h4>
            <div class="text-validation-form-item">
                <label class="field-label">Description</label>
                <textarea class="form-control" rows="7" aria-describedby="ProfileDescriptionHelpBlock" :value="user.description" readonly></textarea>
                <a href="#" class="btn btn-danger btn-sm btn-validation" @click.prevent="rejectDescription">Rejeter</a>
            </div>
        </div>
        <div class="buttons-validation-form">
            <a href="#" class="btn btn-primary btn-sm" @click.prevent="validateAll">Tout valider pour cet utilisateur</a>
        </div>
    </div>
</template>

<script>
    import axios from "axios";

    export default {
        name: "user-validation",
        props: {
            user: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                rejectedPictures: [],
                rejectedProfilePictures: [],
                isDescrValidated: false,
                allValidated: false
            }
        },
        mounted() {
            if (this.user.description == '') {
                this.isDescrValidated = true
            }
        },
        methods: {
            userEdit() {
                this.$router.push(`/${this.$i18n.locale}/admin/profile-edit/${this.user.userSlug}`);
            },
            reject(userPicureId) {
                console.log('reject' + userPicureId)
                this.rejectedPictures.push(userPicureId)
                /* eslint-disable */
                axios.get(`/api/validation/reject-picture/${userPicureId}`)
                    .then(
                        response => {

                        }
                    )
                /* eslint-enable */
            },
            rejectProfilePicture(userId) {
                console.log('reject profile pict' + userId)
                this.rejectedProfilePictures.push(userId)
                /* eslint-disable */
                axios.get(`/api/validation/reject-profile-picture/${userId}`).then(
                    response => {
                        this.user.isPictureValidated = true
                    }
                )
                /* eslint-enable */
            },
            rejectDescription() {
                axios.get(`/api/validation/reject-description/${this.user.user_Id}`)
                this.isDescrValidated = true
            },
            validateAll() {
                console.log('validate')
                this.allValidated = true
                /* eslint-disable */
                axios.get(`/api/validation/validate-user-pictures/${this.user.user_Id}`)
                    //.then(
                    //    response => {

                    //    }
                    //)
                /* eslint-enable */

            }
        }
    }
</script>