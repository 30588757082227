<template>
    <div id="search-filters-settings-form-2" class="d-flex">
        <div class="settings-form-col">
            <label class="field-label">{{ $t("fields.f-height") }}</label>
            <div class="form-inline">
                <custom-select :options="minHeights" :unit="'cm'" :selectid="minHeight.toString()" @input="minHeight = $event;setMinMaxHeight()" />
                <span class="form-conjunction"> {{ $t("à") }} </span>
                <custom-select :options="maxHeights" :unit="'cm'" :selectid="maxHeight.toString()" @input="maxHeight = $event;setMinMaxHeight()" />
            </div>

            <label class="field-label">{{ $t("fields.f-weight") }}</label>
            <div class="form-inline">
                <custom-select :options="minWeights" :unit="'kg'" :selectid="minWeight.toString()" @input="minWeight = $event;setMinMaxWeight()" />
                <span class="form-conjunction"> {{ $t("à") }} </span>
                <custom-select :options="maxWeights" :unit="'kg'" :selectid="maxWeight.toString()" @input="maxWeight = $event;setMinMaxWeight()" />
            </div>



        </div>
        <div class="settings-form-col">
            <div class="form-group">
                <label for="selectSports" class="field-label">{{ $t('Cheveux') }}</label>
                <multi-select name="hair-color" :items="hairColorChoices" :selected="hairColorUserChoices"></multi-select>
            </div>
            <div class="form-group mt-3">
                <multi-select name="hair-length" :items="hairLengthChoices" :selected="hairLengthUserChoices"></multi-select>

            </div>
            <div class="form-group mt-3">
                <multi-select name="hair-type" :items="hairTypeChoices" :selected="hairTypeUserChoices"></multi-select>
            </div>

        </div>
        <div class="settings-form-col">
            <label class="field-label">{{ $t('fields.f-eyes') }}</label>
            <div id="search-filters-settings-hair" class="d-flex">
                <multi-select name="eyes" :items="eyesChoices" :selected="eyesUserChoices"></multi-select>
            </div>
            <label class="mt-3 field-label">{{ $t('fields.f-silhouette') }}</label>
            <div id="search-filters-settings-eyes" class="d-flex">
                <multi-select name="silhouette" :items="silhouetteChoices" :selected="silhouetteUserChoices"></multi-select>
            </div>
            <label class="mt-3 field-label">{{ $t('fields.f-sign') }}</label>
            <div id="search-filters-settings-eyes" class="d-flex">
                <multi-select name="sign" :items="signChoices" :selected="signUserChoices"></multi-select>
            </div>
        </div>
        <div class="settings-form-col">
            <label class="field-label">{{ $t('fields.f-style') }}</label>
            <div id="search-filters-settings-hair" class="d-flex">
                <multi-select name="style" :items="styleChoices" :selected="styleUserChoices"></multi-select>
            </div>
            <label class="mt-3 field-label">{{ $t('fields.f-origin') }}</label>
            <div id="search-filters-settings-eyes" class="d-flex">
                <multi-select name="origin" :items="originChoices" :selected="originUserChoices"></multi-select>
            </div>
            <label class="mt-3 field-label">{{ $t('fields.f-sport') }}</label>
            <div id="search-filters-settings-eyes" class="d-flex">
                <multi-select name="sport" :items="sportChoices" :selected="sportUserChoices"></multi-select>
            </div>
        </div>
    </div>
</template>

<script>
    import MultiSelect from "@/components/shared/fields/MultiSelect.vue";
    import CustomSelect from "@/components/shared/fields/CustomSelect.vue";

    export default {
        name: "tab-physical",
        components: {
            MultiSelect,
            CustomSelect
        },
        props: {
            fields: {
                type: Object,
                required: true
            },
            rangeFields: {
                type: Object,
                required: true
            },
            userChoices: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                minHeights: [],
                maxHeights: [],
                minWeights: [],
                maxWeights: []
            }
        },
        mounted() {
            this.setMinMaxHeight()
            this.setMinMaxWeight()
        },
        computed: {
            locRangeFields: {
                get() { return this.rangeFields },
                set(locRangeFields) { this.$emit('input', locRangeFields) }
            },
            minHeight: {
                get() { return this.locRangeFields.minHeight },
                set(choice) { this.locRangeFields.minHeight = choice }
            },
            maxHeight: {
                get() { return this.locRangeFields.maxHeight },
                set(choice) { this.locRangeFields.maxHeight = choice }
            },
            minWeight: {
                get() { return this.locRangeFields.minWeight },
                set(choice) { this.locRangeFields.minWeight = choice }
            },
            maxWeight: {
                get() { return this.locRangeFields.maxWeight },
                set(choice) { this.locRangeFields.maxWeight = choice }
            },
            eyesChoices() {
                return this.fields.filter(c => c.slug === 'eyes')[0].choices
            },
            eyesUserChoices: {
                get() { return this.getUserChoices('eyes') },
                set(choice) { this.userChoices.filter(c => c.field_Slug === 'eyes')[0].choices = choice }
            },
            silhouetteChoices() {
                return this.fields.filter(c => c.slug === 'silhouette')[0].choices
            },
            silhouetteUserChoices: {
                get() { return this.getUserChoices('silhouette') },
                set(choice) { this.userChoices.filter(c => c.field_Slug === 'silhouette')[0].choices = choice }
            },
            hairColorChoices() {
                return this.fields.filter(c => c.slug === 'hair-color')[0].choices
            },
            hairColorUserChoices: {
                get() { return this.getUserChoices('hair-color') },
                set(choice) { this.userChoices.filter(c => c.field_Slug === 'hair-color')[0].choices = choice }
            },
            hairLengthChoices() {
                return this.fields.filter(c => c.slug === 'hair-length')[0].choices
            },
            hairLengthUserChoices: {
                get() { return this.getUserChoices('hair-length') },
                set(choice) { this.userChoices.filter(c => c.field_Slug === 'hair-length')[0].choices = choice }
            },
            hairTypeChoices() {
                return this.fields.filter(c => c.slug === 'hair-type')[0].choices
            },
            hairTypeUserChoices: {
                get() { return this.getUserChoices('hair-type') },
                set(choice) { this.userChoices.filter(c => c.field_Slug === 'hair-type')[0].choices = choice }
            },

            signChoices() {
                return this.fields.filter(c => c.slug === 'sign')[0].choices
            },
            signUserChoices: {
                get() { return this.getUserChoices('sign') },
                set(choices) { this.userChoices.filter(c => c.field_Slug === 'sign')[0].choices = choices }
            },

            styleChoices() {
                return this.fields.filter(c => c.slug === 'style')[0].choices
            },
            styleUserChoices: {
                get() { return this.getUserChoices('style') },
                set(choices) { this.userChoices.filter(c => c.field_Slug === 'style')[0].choices = choices }
            },

            originChoices() {
                return this.fields.filter(c => c.slug === 'origin')[0].choices
            },
            originUserChoices: {
                get() { return this.getUserChoices('origin') },
                set(choices) { this.userChoices.filter(c => c.field_Slug === 'origin')[0].choices = choices }
            },

            sportChoices() {
                return this.fields.filter(c => c.slug === 'sport')[0].choices
            },
            sportUserChoices: {
                get() { return this.getUserChoices('sport') },
                set(choices) { this.userChoices.filter(c => c.field_Slug === 'sport')[0].choices = choices }
            }
        },
        methods: {
            getUserChoice(slug) {
                let filtered = this.userChoices.filter(c => c.field_Slug === slug)
                if (filtered.length) {
                    let field = filtered[0]
                    if (field.choices.length) {
                        return field.choices[0]
                    }
                }
                return '';
            },
            getUserChoices(slug) {
                let filtered = this.userChoices.filter(c => c.field_Slug === slug)
                if (filtered.length) {
                    return filtered[0].choices
                }
                return []
            },
            setMinMaxHeight() {
                const min = parseInt(this.locRangeFields.minHeight)
                const max = parseInt(this.locRangeFields.maxHeight)
                let i = 0
                this.minHeights = []
                for (i = 100; i <= max; i++) {
                    this.minHeights.push(i.toString())
                }
                this.maxHeights = []
                for (i = min + 1; i < 251; i++) {
                    this.maxHeights.push(i.toString())
                }
            },
            setMinMaxWeight() {
                const min = parseInt(this.locRangeFields.minWeight)
                const max = parseInt(this.locRangeFields.maxWeight)
                let i = 0
                this.minWeights = []
                for (i = 35; i <= max; i++) {
                    this.minWeights.push(i.toString())
                }
                this.maxWeights = []
                for (i = min + 1; i < 201; i++) {
                    this.maxWeights.push(i.toString())
                }
            }
        },
    }
</script>

<style scoped>
    .custom-select {
        height: 26px;
        line-height: 26px;
    }
</style>
