<template>
    <div class="col-md-12 blocks-list">

        <div class="block-list-item box can-drop" v-for="(content, index) in contentList" :key="index"
             draggable="true"
             @dragstart="startDrag($event, content)"
             @dragend="endDrag($event)"
             @dragover.prevent="dragEnter($event)"
             @dragenter="dragEnter($event)"
             @dragleave="dragLeave($event)"
             @drop="handleDrop($event, content)">
            <div class="block-list-item-position-buttons">{{ (content.isPublished)?'publié':'non publié' }}</div>
            <div class="block-list-principe">{{ content.templateName }}</div>
            <div class="block-list-item-name">{{ content.title }}</div>
            <div class="block-list-item-buttons">
                <a href="#" @click.prevent="deleteContent(content.id)"><i class="far fa-trash-alt" aria-hidden="true"></i></a>
                <a href="#" @click.prevent="editContent(content.id)"><i class="far fa-edit"></i></a>
            </div>
        </div>

    </div>
</template>

<script>
    import axios from "axios";

    export default {
        name: "content-list",
        props: {
            contents: Object
        },
        data() {
            return {
                data: [],
                dragSrcEl: null,
            }
        },
        mounted() {
            this.data = this.contents
        },
        computed: {
            contentList() {
                if (this.data != null) {
                    return this.data.slice().sort(function (a, b) {
                        return a.position - b.position;
                    });
                } else {
                    return []
                }
                
            },
        },
        methods: {
            startDrag(e, item) {
                if (e != undefined) {
                    e.stopPropagation()
                    e.target.style.opacity = '0.4';
                    this.dragSrcEl = e.target;
                    e.dataTransfer.effectAllowed = 'move';
                    e.dataTransfer.setData('position', item.position);
                }
            },
            dragOver(e) {
                e.dataTransfer.dropEffect = 'move';
                return false
            },
            dragEnter(e) {
                if (e != undefined) {
                    e.target.classList.add('over');
                }
            },
            dragLeave(e) {
                e.target.classList.remove('over');
            },
            handleDrop(e, item) {
                if (e.stopPropagation) {
                    e.stopPropagation(); // stops the browser from redirecting.
                }
                // repositionnement
                if (this.dragSrcEl !== e.target && e.target.classList.contains('can-drop')) {
                    let dropPosition = item.position
                    let draggedPosition = e.dataTransfer.getData('position');
                    let dragged = this.data.filter(c => c.position == draggedPosition)[0]
                    if (dropPosition > draggedPosition) {
                        console.log('montée')
                        let betweenContent = this.data.filter(c => c.position > draggedPosition && c.position <= dropPosition)
                        Array.from(betweenContent).forEach(cont =>
                            cont.position--
                        )
                        //this.data.placeContents.filter(c => c.position == draggedPosition)[0].position = dropPosition
                    } else {
                        let betweenContent = this.data.filter(c => c.position < draggedPosition && c.position >= dropPosition)
                        console.log('descente')
                        Array.from(betweenContent).forEach(cont =>
                            cont.position++
                        )
                    }
                    dragged.position = dropPosition
                }

                // Post order change
                axios.post("/api/admincontents/change-positions", this.data)

                return false;
            },
            endDrag(evt) {
                evt.target.style.opacity = '1';
                document.querySelector(".box").classList.remove('over')
            },
            editContent(content_id) {
                console.log("edit content")
                this.$router.replace(`/${this.$i18n.locale}/admin/content-edit/${content_id}`);
            },
            //createContent(slug) {
            //    this.$router.push(`/${this.$i18n.locale}/admin/content-create/${slug}`);
            //},
            deleteContent(content_id) {
                if (confirm('Voulez-vous vraiment supprimer ce contenu? Cette action est irréversible.')) {
                    this.data = this.data.filter(c => c.id != content_id)
                    axios.get(`/api/admincontents/delete/${content_id}`)
                }
            },
        },
        
    }
</script>

<style scoped>
    /*.dropdown-menu {
        right: 0;
        left: auto;
        display: block;
    }*/

    .box {
        /*border: 3px solid #666;
        background-color: #ddd;
        border-radius: .5em;
        padding: 10px;*/
        cursor: move;
    }

        .box.over {
            border: 2px dotted #666;
        }

    [draggable] {
        user-select: none;
    }
</style>