// Middelware utilisé pour les refresh token

import axios from "axios";
import { store } from "../store";
//import router from "@/router";


axios.interceptors.response.use(undefined, function (error) {
    const originalRequest = error.config;
    if (
        error.response.status === 401 &&
        !originalRequest._retry &&
        store != undefined &&
        store.state.auth.refresh_token
    ) {
        originalRequest._retry = true;
        console.log('** interceptor Token = ' + store.state.auth.refresh_token)
        const payload = {
            refresh_token: store.state.auth.refresh_token
        };

        return axios
            .post("/api/token/refresh", payload)
            .then(response => {
                const auth = response.data;
                axios.defaults.headers.common["Authorization"] = `Bearer ${auth.access_token}`;
                originalRequest.headers["Authorization"] = `Bearer ${auth.access_token}`;
                store.commit("loginSuccess", auth);
                //console.log("** interceptor ok")
                store.dispatch("setConnection")

                return axios(originalRequest);
            })
            .catch(error => {
                console.log('** interceptor error')

                store.commit("logout");
                delete axios.defaults.headers.common["Authorization"];
                console.log(error)
                //window.location.href = '/'

                //router.push({ path: "/" })
                //router.push('/');
                return Promise.reject(error);
            });
    }

    return Promise.reject(error);
});
