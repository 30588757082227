<template>
    <!--<Form>-->
        <div v-if="user">
            <input id="id" name="id" type="hidden" v-model="user.id">
            <div class="form-group row">
                <label for="lastName" class="col-lg-3 field-label">{{ $t('fields.lastName') }} *</label>
                <div class="col-lg-9">
                    <input type="text" id="lastName" class="form-control" v-model="user.lastName" maxlength="100" required>
                    <div v-if="errors['lastName']" class="err-msg">{{ errors['lastName'] }}</div>
                </div>
            </div>
            <div class="form-group row">
                <label for="firstName" class="col-lg-3 field-label">{{ $t('fields.firstName') }} *</label>
                <div class="col-lg-9">
                    <input type="text" id="firstName" class="form-control" v-model="user.firstName" maxlength="100" required>
                    <div v-if="errors['firstName']" class="err-msg">{{ errors['firstName'] }}</div>
                </div>
            </div>
            <div class="form-group row">
                <label for="pseudo" class="col-lg-3 field-label">{{ $t('fields.pseudo') }} *</label>
                <div class="col-lg-9">
                    <input type="text" id="pseudo" class="form-control" v-model="user.pseudo" required>
                    <div v-if="errors['pseudo']" class="err-msg">{{ errors['pseudo'] }}</div>
                </div>
            </div>
            <div class="form-group row">
                <label for="email" class="col-lg-3 field-label">{{ $t('fields.email') }} *</label>
                <div class="col-lg-9">
                    <input type="text" id="email" class="form-control" v-model="user.email" maxlength="256" required>
                    <div v-if="errors['email']" class="err-msg">{{ errors['email'] }}</div>
                </div>
            </div>
            <div class="form-group row">
                <label for="phoneNumber" class="col-lg-3 field-label">Téléphone</label>
                <div class="col-lg-9">
                    <input type="text" id="phoneNumber" class="form-control" v-model="user.phoneNumber" maxlength="100" required>
                </div>
            </div>
            <div class="form-group row">
                <label for="phoneNumber" class="col-lg-3 field-label">{{ $t("fields.language") }}</label>
                <div class="col-lg-9">
                    <custom-select-val-label :options="languages" :selectid="user.language" @input="user.language = $event" />
                </div>
            </div>
            <div class="form-group row">
                <label for="status" class="col-lg-3 field-label">Statut *</label>
                <div class="col-lg-9">
                    <select class="form-control" id="status" v-model="user.status">
                        <option v-for="status in statusList" :key="status" :value="status.value">{{ status.label }}</option>
                    </select>
                </div>
            </div>
            <hr>
            <!--------------------->
            <!-- mot de passe -->
            <div class="form-group row">
                <label for="password" class="col-lg-3 field-label">{{ $t("fields.password") }}</label>
                <div class="col-lg-9">
                    <input type="password" id="password" autocomplete="new-password" class="form-control" v-model="user.password" @input="checkPassword">
                    <small id="PasswordHelpBlock" class="form-text text-muted">{{ $t("fields.password-requ") }}</small>
                    <div v-if="pwError1" class="err-msg">
                        {{ $t("fields.password-format") }}
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <label for="passwordConfirm" class="col-lg-3 field-label">{{ $t("fields.password-confirm") }}</label>
                <div class="col-lg-9">
                    <input type="password" id="passwordConfirm" autocomplete="new-password" class="form-control" v-model="passwordConfirm" @input="checkPasswordConfirm">
                    <div v-if="pwError2" class="err-msg">
                        {{ $t("fields.password-conf-error") }}
                    </div>
                </div>
            </div>
            <hr>
            <!-- mot de passe -->
            <!--------------------->
            <!------------------->
            <!-- Abonnement -->
            <div v-if="user.status != 'Admin'">
                <div class="form-group row">
                    <label for="selectSubscriptionFormula" class="col-lg-3 field-label">{{ $t("fields.subscription") }}</label>
                    <div class="col-lg-9">
                        <select class="form-control" id="selectSubscriptionFormula" v-model="user.subscriptionType_Id">
                            <option value="0">Sélectionnez votre formule</option>
                            <option selected v-for="st in subscriptionTypes" :key="st.id" :value="st.id">{{ st.name }}</option>
                        </select>
                    </div>
                </div>
                <div class="form-group row">
                    <label for="inputSubscriptionPayment" class="col-lg-3 field-label">Date de paiement</label>
                    <div class="col-lg-9">
                        <span v-if="user.subscriptionDate">
                            {{ $filters.date(user.subscriptionDate) }}
                        </span>
                        <span v-else>Pas de date de paiement</span>
                    </div>
                </div>
                <div class="form-group row">
                    <label for="inputSubscriptionEnd" class="col-lg-3 field-label">{{ $t("fields.expiry") }}</label>
                    <div class="col-lg-9">
                        <date-field :minYearFromToday="-10" :maxYearFromToday="10" :selectedDate="user.expirationDate" @update="user.expirationDate = $event"></date-field>
                    </div>
                </div>
            </div>
            <!-- Abonnement -->
            <!------------------->

            <hr>
            <div class="form-group row">
                <label for="gender" class="col-lg-3 field-label">{{ $t('fields.f-gender') }} *</label>
                <div class="col-lg-9">
                    <custom-select-val-label :options="genders" :selectid="user.gender" @input="user.gender = $event" />
                    <div v-if="errors['gender']" class="err-msg">{{ errors['gender'] }}</div>
                </div>
            </div>
            <div class="form-group row">
                <label for="birthDate" class="col-lg-3 field-label">Date de naissance *</label>
                <div class="col-lg-9">
                    <date-field :minYearFromToday="-100" :maxYearFromToday="-18" :selectedDate="user.birthDate" @update="user.birthDate = $event"></date-field>
                    <div v-if="errors['birthDate']" class="err-msg">{{ errors['birthDate'] }}</div>
                </div>
            </div>

            <div class="form-group row">
                <label for="zipCode" class="col-lg-3 field-label">Ville ou code postal *</label>
                <div class="col-lg-9">
                    <zip-code :zipCode="user.zipCode" :city="user.city" @input="user.zipCode = $event" @error="emitZipError($event)"></zip-code>
                    <div v-if="errors['zipCode']" class="err-msg">{{ errors['zipCode'] }}</div>
                </div>
            </div>
            <div class="form-group row">
                <label for="description" class="col-lg-3 field-label">Description</label>
                <div class="col-lg-9">
                    <textarea class="form-control" id="description" rows="7" v-model="user.description"></textarea>
                </div>
            </div>
            <div class="form-group row">
                <label for="adminComment" class="col-lg-3 field-label">Commentaire</label>
                <div class="col-lg-9">
                    <textarea class="form-control" id="adminComment" rows="7" v-model="user.adminComment"></textarea>
                </div>
            </div>
        </div>
    <!--</Form>-->
</template>

<script>
    import ZipCode from "../../shared/fields/ZipCodeField"
    import DateField from "../../shared/fields/DateField"
    import CustomSelectValLabel from "@/components/shared/fields/CustomSelectValLabel.vue";

    export default {
        name: "admin-edit-user",
        emits: ["error"],
        components: {
            ZipCode,
            DateField,
            CustomSelectValLabel
        },
        props: {
            userData: {
                type: Object,
            },
            statusList: {
                type: Array
            },
            subscriptionTypes: {
                type: Array
            },
            errors: {
                type: Object,
            }
        },
        data() {
            return {
                passwordConfirm: '',
                pwError1: false,
                pwError2: false,
                zipError: false,
                genders: [
                    { value: "", label: this.$t('Sélectionnez votre genre') },
                    { value: "W", label: this.$t('fields.genders.W') },
                    { value: "M", label: this.$t('fields.genders.M') },
                    { value: "O", label: this.$t('fields.genders.O') }
                ],
                languages: [
                        { value: "fr", label: this.$t('fields.langs.fr') },
                        { value: "nl", label: this.$t('fields.langs.nl') },
                        { value: "en", label: this.$t('fields.langs.en') }
                    ]
            }
        },
        computed: {
            user: {
                get() { return this.userData },
                set(user) { this.$emit('input', user) }
            }
        },
        methods: {
            isRequired(value) {
                if (value && value.trim()) {
                    return true;
                }

                return 'This is required';
            },
            checkPassword() {
                // Check format
                var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$&+,:;=?@#|'<>.^*()%!-])(?=.{8,})");
                if (this.user.password == null || this.user.password == "" || strongRegex.test(this.user.password)) {
                    this.pwError1 = false
                } else {
                    this.pwError1 = true
                }
                // Check confirm
                if ((this.user.password != null && this.user.password.length) && this.user.password != this.passwordConfirm) {
                    this.pwError2 = true
                } else {
                    this.pwError2 = false
                }
                this.emitError()
            },
            checkPasswordConfirm() {
                if (((this.user.password != null && this.user.password.length) || this.passwordConfirm.length) && this.user.password != this.passwordConfirm) {
                    this.pwError2 = true
                } else {
                    this.pwError2 = false
                }
                this.emitError()
            },
            emitError() {
                if (this.pwError1 || this.pwError2 || this.zipError) {
                    this.$emit("error", true);
                } else {
                    this.$emit("error", false);
                }
            },
            emitZipError(event) {
                this.zipError = event
                this.emitError()
            }
        }
    }
</script>

<style lang="scss" scoped>
    .err-msg {
        width: 100%;
        margin-top: .25rem;
        font-size: 80%;
        color: #dc3545
    }
</style>