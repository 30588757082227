<template>
    <div class="custom-select" :tabindex="tabindex" @blur="open = false">
        <div class="selected" :class="{ open: open }" @click="open = !open">
            {{ (unit)? selected + ' ' + unit : selected }}
        </div>
        <div class="items" :class="{ selectHide: !open }">
            <div v-for="(option, i) of options"
                 :key="i"   
                 @click="
          selected = option.label;
          open = false;
          $emit('input', option.value);
        ">
                {{ option.label }}
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            options: {
                type: Array,
                required: true,
            },
            selectid: {
                type: String,
                required: false,
                default: null,
            },
            tabindex: {
                type: Number,
                required: false,
                default: 0,
            },
            unit: {
                type: String,
                default: ''
            }
        },
        data() {
            return {
                selected: null,
                open: false,
            };
        },
        watch: {
            selectid: function (val) {
                this.setSelected(val)
            },
            options: function () {
                this.setSelected(this.selectid)
                //if (this.selectid != null) {
                //    let selected = this.options.filter(o => o.value == this.selectid.toString())
                //    if (selected[0] != undefined) {
                //        this.selected = selected[0].label
                //    }
                //} else {
                //    this.selected = this.options[0].label
                //}
                console.log(this.options[0].label)

            },
        },
        mounted() {
            this.setSelected(this.selectid)
        },
        methods: {
            setSelected(val) {
                let sel = null
                if (val) {
                    if (this.options.filter(o => o.value == val.toString()).length > 0) {
                        sel = this.options.filter(o => o.value == val.toString())[0].label
                    } else {
                        sel = this.options[0].label
                    }
                } else {
                    if (this.options != null && this.options.length > 0) {
                        sel = this.options[0].label
                    } else {
                        sel = null
                    }
                }
                this.selected = sel
            }
        }
    };
</script>

<style scoped>
    .custom-select {
        position: relative;
        width: 100%;
        text-align: left;
        outline: none;
        height: 38px;
        line-height: 38px;
        padding: 0;
        background: url("/images/select-arrow-down.png") rgba(255, 167, 96, 1) no-repeat calc(100% - 10px) !important;
        border: 0;
        margin-top: .6em;
    }
    .custom-select:first-child {
        margin-top: 0;
    }

    #search-filters-settings-forms .custom-select {
        height: 26px;
        line-height: 26px;
        font-size: .9rem;
        text-align: left;
    }

    .custom-select .selected {
        border-radius: 0.25rem;
        border: 0;
        border-bottom: 1px solid rgb(168,1,35);
        color: rgb(168,1,35);
        cursor: pointer;
        user-select: none;
        text-align: center;
        padding: 0 32px 0 10px;
    }

    #search-filters-settings-forms .custom-select .selected {
        text-align: left;
    }

    .custom-select:focus {
        outline: none;
        box-shadow: 0 0 0 4px rgba(168,1,35,0.4);
    }

    .custom-select:focus .selected {
        border-bottom: 0;
    }

    .custom-select .selected.open {
        background: url("/images/select-arrow-up.png") rgba(255, 167, 96, 1) no-repeat calc(100% - 10px) !important;
    }

    .custom-select .items {
        color: #fff;
        border-radius: 0px 0px 6px 6px;
        box-shadow: 0 0 0 4px rgba(168,1,35,0.4);
        overflow: hidden;
        position: absolute;
        background-color: rgba(243, 127, 34, 1);
        left: 0;
        right: 0;
        z-index: 1;
        margin-top: 3px;
        max-height: 140px;
        overflow-y: scroll;
    }

    .custom-select .items div {
        color: #fff;
        padding-left: .5em;
        cursor: pointer;
        user-select: none;
    }

    .custom-select .items div:hover {
        background-color: rgb(168,1,35);
    }

    .selectHide {
        display: none;
    }

    @media (max-width: 575px) {
        .col-sm {
            flex-basis: 0!important;
            flex-grow: 1!important;
            max-width: 100%!important;
            margin-bottom:.5em;
        }
    }

</style>